import React, { useContext, useState } from 'react'
import AsyncSelect from 'react-select/async'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Swal from 'sweetalert2'
import { KTSVG } from '../../_metronic/helpers'
import { getRequestOptions } from '../components/GetToken'
import { postRequestOptions } from '../helpers/Fetchwrapper'
import { AppContext } from '../context/AppContext'
import WipQcModal from '../components/WipQcModal'

const customStylesSelect = {
  control: (provided) => ({
    ...provided,
    height: 'calc(1.35em + 1.1rem + 2px)',
    minHeight: '35px',
  }),
  valueContainer: (provided) => ({
    ...provided,
  }),
}

function WipQc(props) {
  const [qcDate, setQcDate] = useState('')
  const [operation, setOperation] = useState('')
  const [qcItems, setQcItems] = useState([])
  const [wipQcPropsData, setWipQcPropsData] = useState({
    orderId : '',
    itemName: '',
    orderQty: '',
    productionQty: '',
    qcPassedQty: '',
    qcDamagedQty: ''
  })


  const [showWipQcModal, setShowWipQcModal] = useState(false)
 
  const handleShowWipQcModal = (wipWCData) => {
    setShowWipQcModal(true)
    setWipQcPropsData({...wipWCData,operationId:operation.value,operationName:operation.label,buId:bu})
  }
  
  const handleWipQcModalClose = (isSavedNewQc) => {
    setShowWipQcModal(false)
    if(isSavedNewQc){
      getWipQcItems(operation.value)
    }
  }
  

  const {bu} = useContext(AppContext)

  const getOperation = (inputValue, callback) => {
    if (!inputValue) {
      var api = process.env.REACT_APP_API_URL + 'operation_search'
    } else {
      api = process.env.REACT_APP_API_URL + 'operation_search?q=' + inputValue
    }

    setTimeout(() => {
      fetch(api, getRequestOptions())
        .then((resp) => {
          return resp.json()
        })
        .then((resp) => {
          const tempArray = resp.data.map(function (element) {
            return {...element, label: `${element.operationName}`, value: element.operationId}
          })
          callback(tempArray)
        })
        .catch((error) => {
          console.log(error, 'catch the hoop')
        })
    })
  }

  const handleOperationChange = (item) => {
    setOperation(item)
    //console.log(item);
    getWipQcItems(item.operationId)
  }

  const getWipQcItems = (operationId) => {
    fetch(`${process.env.REACT_APP_API_URL}get_order_to_qc/${bu}/${operationId}`, getRequestOptions())
      .then((resp) => {
        return resp.json()
      })
      .then((resp) => {
        //console.log(resp.data);
        setQcItems(resp.data)
      })
     
  }



 


  return (
    <>
      <div className='card card-custom mt-5'>
        <div className='card-header'>
          <div className='card-title'>
            <KTSVG
              path='/media/icons/duotune/text/txt001.svg'
              className='svg-icon-1 svg-icon-primary'
            />
            <span className='text fw-bolder' style={{marginLeft: '10px'}}>
              Work In Progress QC
            </span>
          </div>
        </div>

        <div className='card-body'>
          <div className='ml-12'>
         
            

              <div className='form-group row'>
                <label
                  htmlFor='operationName'
                  className='col-lg-3 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Select  Operation<span className='required text-danger'></span>
                </label>
                <div className='col-lg-5'>
                  <AsyncSelect
                    value={operation}
                    defaultOptions
                    loadOptions={getOperation}
                    onChange={handleOperationChange}
                    getOptionLabel={(option) => (
                      <>
                        <span> {option.label} </span>
                        <br></br>
                       
                      </>
                    )}
                  />
                </div>
              </div>
              <div className='col-12'>
                <div className='table-responsive'>
                  <table
                    className='table table-bordered table-hover'
                    style={{minWidth: 'max-content'}}
                  >
                    <thead>
                      <tr>
                        <th>Order No</th>
                        <th>Date</th>
                        <th>Production Item</th>
                        <th>Order  Qty</th>
                        <th>Production Qty</th>
                        <th>QC Passed Qty</th>
                        <th>Damage Qty</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {qcItems.map((item, index) => (
                        <tr key={index}>
                          <td>{item.orderNo}</td>
                          <td>{item.orderDate}</td>
                          <td>{item.itemName}</td>
                          <td>{item.orderQty}</td>
                          <td>{item.productionQty}</td>
                          <td>{item.qcPassedQty}</td>
                          <td>{item.qcDamageQty}</td>
                          <td>
                            <button className='btn btn-sm btn-primary' onClick={()=>handleShowWipQcModal(item)}>Qc</button>
                          </td>
                          <td>

                          </td>


                        </tr>
                      ))}
                      
                    </tbody>
                  </table>
                </div>
              </div>

             
              {/* <pre>{JSON.stringify(wipItems, null, 2)}</pre> */}
          
          </div>
        </div>
      </div>
      {/* <ToastContainer /> */}

      <WipQcModal show={showWipQcModal} handleClose={handleWipQcModalClose} wipQcPropsData={wipQcPropsData} />
    </>
  )
}

export default WipQc