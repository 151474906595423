import React from 'react'
import DataTable from '../components/DataTable'
import { KTSVG } from '../../_metronic/helpers'


function OrderWipList() {

	const columns = [
		{ value: 'id', title: 'Id', style: { textAlign: 'center' } },
		{ value: 'poNo', title: 'Order No', style: { textAlign: 'center' } },
		{ value: 'operationName', title: 'Operation', style: { textAlign: 'center' } },
		{ value: 'itemName', title: 'Production Item', style: { textAlign: 'center' } },
		{ value: 'productionQty', title: 'Production Qty', style: { textAlign: 'center' } },
		{ value: 'bomDuration', title: 'Bom Duration (Minutes)', style: { textAlign: 'center' } },
		{ value: 'actualDuration', title: 'Actual Duration (Minutes)', style: { textAlign: 'center' } },
		{ value: 'orderWipDate', title: 'Work-in-progress Date', style: { textAlign: 'center' } },
		{
			value: 'action', title: 'Action', actions: [
				{ link: 'order-wip-details', calAction: false }
			],
			style: { textAlign: 'center' }
		}
	]

	const filters = {
		id: {
			filterType: 'like',
			filterValue: '',
		},
		poNo: {
			filterType: 'like',
			filterValue: '',
		},
		operationName: {
			filterType: 'like',
			filterValue: '',
		},
		itemName: {
			filterType: 'like',
			filterValue: '',
		},
		productionQty: {
			filterType: 'like',
			filterValue: '',
		},
		bomDuration: {
			filterType: 'like',
			filterValue: '',
		},
		actualDuration: {
			filterType: 'like',
			filterValue: '',
		},
		orderWipDate: {
			filterType: 'like',
			filterValue: '',
		}

	}

	const url = process.env.REACT_APP_API_URL + 'order/wip_dt'


	return (
		<>
			<div className='card card-custom'>
				<div className='card-header'>
					<div className='card-title'>
						<KTSVG
							path='/media/icons/duotune/text/txt001.svg'
							className='svg-icon-1 svg-icon-primary'
						/>
						<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
							Work-in-progress List
						</span>
					</div>
				</div>

				<div className='card-body'>


					<DataTable url={url} columns={columns} filters={filters} >

						<span className='svg-icon svg-icon-md svg-icon-primary'>
							<KTSVG path='/media/svg/icons/Text/Bullet-list.svg' className='svg-icon-1 svg-icon-3' />
						</span>

					</DataTable>


				</div>
			</div>
		</>
	)
}

export default OrderWipList
