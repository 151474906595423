/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getRequestOptions } from './GetToken'
import { postRequestOptions } from '../helpers/Fetchwrapper'
import AsyncSelect from 'react-select/async';
import validationMessage from '../helpers/ValidationErrorMsg';

const customStylesSelect = {
	control: (provided) => ({
		...provided,
		height: 'calc(1.35em + 1.1rem + 2px)',
		minHeight: '35px'
	}),
	valueContainer: (provided) => ({
		...provided,
	})
};

function EmployeeCreate(props) {
	let history = useHistory()
	const [id, setId] = useState('')
	const [employeeId, setEmployeeId ] = useState('')
	const [employeeName, setEmployeeName] = useState('')
	const[mobileNo,setMobileNo]=useState('')
	const [email, setEmail] = useState('')
	const [department, setDepartment] = useState('')
	const [designation, setDesignation] = useState('')
	const [address, setAddress] = useState('')
	const [grossSalary, setGrossSalary] = useState('')
	const [remarks, setRemarks] = useState('')

	const myRef = useRef()

	useEffect(() => {
		//setoperationName(props.name);
		if (id !== props.id || employeeName !== props.name) {
			setId(props.id)
			getEmployeeDetails(props.id)
			window.scrollTo(0, 0)
		}
	}, [props.id, props.name])

	const getEmployeeDetails = (employeeId) => {
		fetch(process.env.REACT_APP_API_URL + 'production_employee_details/' + employeeId, getRequestOptions())
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				setEmployeeId(resp.employeeId)
				setEmployeeName(resp.employeeName)
				setMobileNo(resp.mobileNo)
				setEmail(resp.email)
				setDepartment(resp.department)
				setDesignation(resp.designation)
				setAddress(resp.address)
				setGrossSalary(resp.grossSalary)	

			})
			.catch((error) => {
				console.log(error, 'catch the hoop')
			})
	}


	const handleSubmit = async (evt) => {
		evt.preventDefault();
		const isUpdate = id !== '';
		const confirmationText = isUpdate
		  ? `You want to update this Employee!`
		  : `You want to add this Employee!`;
	
		try {
		  const result = await Swal.fire({
			title: 'Are you sure?',
			text: confirmationText,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
		  });
	
		  if (result.isConfirmed) {
			const formData = {
			  id: id,
			  employeeId: employeeId,
			  employeeName: employeeName,
			  mobileNo: mobileNo,
			  email: email,
			  department: department,
			  designation: designation,
			  address: address,
			  grossSalary: grossSalary,
			};
	
			const requestOptions = postRequestOptions(formData);
			const apiEnd = isUpdate ? 'production_employee_update' : 'production_employee_create';
	
			const resp = await fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions);
			const data = await resp.json();
	
			if (data.success === true) {
			  const successMsg = isUpdate
				? [`Employee ${data.data.name} updated`]
				: [`Employee ${data.data.name} created`];
	
			  Swal.fire({
				icon: 'success',
				title: 'Success',
				text: successMsg,
			  });
	
			  resetForm();
			  props.onCreate({ id: data.data.id, name: data.data.name });
			} else {
				const errorsMessage = validationMessage(data.errors).join("<br>");
			  Swal.fire({
				icon: 'error',
				title: 'Error',
				html: errorsMessage,
			  });
			}
		  }
		} catch (error) {
		  console.error('Error during employee submission:', error);
		}
	  };
	
	  const resetForm = () => {
		setId('');
		setEmployeeName('');
		setEmployeeId('');
		setMobileNo('');
		setEmail('');
		setDepartment('');
		setDesignation('');
		setAddress('');
		setGrossSalary('');
	  };

	return (
		<>
			<div className='card-body'>
				<div className='ml-12'>
					<form onSubmit={handleSubmit}>
						<div className='row'>
							<div className='col-6'>
								<div className='form-group row'>
									<label
										htmlFor='employeeId'
										className='col-lg-4 col-form-label'
										style={{ textAlign: 'right' }}
									>
										Employee  ID<span className='required text-danger'></span>
									</label>
									<div className='col-lg-8'>
										<input
											type='text'
											name='employeeId'
											id='employeeId'
											ref={myRef}
											value={employeeId}
											onChange={(e) => setEmployeeId(e.target.value)}
											className='form-control form-control-sm'
										/>
									</div>
								</div>

								<div className='form-group row'>
									<label
										htmlFor='employeeName'
										className='col-lg-4 col-form-label'
										style={{ textAlign: 'right' }}
									>
										Employee Name<span className='required text-danger'></span>
									</label>
									<div className='col-lg-8'>
										<input
											type='text'
											name='employeeName'
											id='employeeName'
											value={employeeName}
											onChange={(e) => setEmployeeName(e.target.value)}
											className='form-control form-control-sm'
										/>
									</div>
								</div>

								<div className='form-group row'>
									<label
										htmlFor='mobileNo'
										className='col-lg-4 col-form-label'
										style={{ textAlign: 'right' }}
									>
										Mobile No<span className='required text-danger'></span>
									</label>
									<div className='col-lg-8'>
										<input
											type='text'
											name='mobileNo'
											id='mobileNo'
											value={mobileNo}
											onChange={(e) => setMobileNo(e.target.value)}
											className='form-control form-control-sm'
										/>
									</div>
								</div>

								<div className='form-group row'>
									<label
										htmlFor='email'
										className='col-lg-4 col-form-label'
										style={{ textAlign: 'right' }}
									>
										Email
									</label>
									<div className='col-lg-8'>
										<input
											type='text'
											name='email'
											id='email'
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											className='form-control form-control-sm'
										/>
									</div>
								</div>

								<div className='form-group row'>
									<label
										htmlFor='department'
										className='col-lg-4 col-form-label'
										style={{ textAlign: 'right' }}
									>
										Department
									</label>
									<div className='col-lg-8'>
										<input
											type='text'
											name='department'
											id='department'
											value={department}
											onChange={(e) => setDepartment(e.target.value)}
											className='form-control form-control-sm'
										/>
									</div>
								</div>

								<div className='form-group row'>
									<label
										htmlFor='designation'
										className='col-lg-4 col-form-label'
										style={{ textAlign: 'right' }}
									>
										Designation<span className='required text-danger'></span>
									</label>
									<div className='col-lg-8'>
										<input
											type='text'
											name='designation'
											id='designation'
											value={designation}
											onChange={(e) => setDesignation(e.target.value)}
											className='form-control form-control-sm'
										/>
									</div>
								</div>

								<div className='form-group row'>
									<label
										htmlFor='address'
										className='col-lg-4 col-form-label'
										style={{ textAlign: 'right' }}
									>
										Address
									</label>
									<div className='col-lg-8'>
										<input
											type='text'
											name='address'
											id='address'
											value={address}
											onChange={(e) => setAddress(e.target.value)}
											className='form-control form-control-sm'
										/>
									</div>
								</div>

								<div className='form-group row'>
									<label
										htmlFor='grossSalary'
										className='col-lg-4 col-form-label'
										style={{ textAlign: 'right' }}
									>
										Gross Salary<span className='required text-danger'></span>
									</label>
									<div className='col-lg-8'>
										<input
											type='text'
											name='grossSalary'
											id='grossSalary'
											value={grossSalary}
											onChange={(e) => setGrossSalary(e.target.value)}
											className='form-control form-control-sm'
										/>
									</div>
								</div>

							</div>

						</div>

						<div className='row'>
							<div className='col-4'></div>
							<div className='col-6'>
								<input
									type='submit'
									className='btn btn-sm btn-success mt-2'
									value={id !== '' ? 'Update' : 'Save'}
								/>
							</div>
						</div>
						{/* <pre>
            {JSON.stringify(operationName, null, 2)}
            {JSON.stringify(status, null, 2)}
          </pre> */}
					</form>
				</div>
				{/* <ToastContainer /> */}
			</div>
		</>
	)
}

export default EmployeeCreate
