
import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FallbackView } from '../../_metronic/partials';
import BomCreate from '../pages/BomCreate';
import BomDetails from '../pages/BomDetails';
import BomList from '../pages/BomList';
import BomUpdate from '../pages/BomUpdate';
import CompanySelection from '../pages/CompanySelection';
import DailyProductionReport from '../pages/DailyProductionReport';
import Dashboard from '../pages/Dashboard';
import Expense from '../pages/Expense';
import Operations from '../pages/Operations';
import PageList from '../pages/PageList';
import PageManagement from '../pages/PageManagement';
import ProductionItemMovementReport from '../pages/ProductionItemMovementReport';
import QcCutting from '../pages/QcCutting';
import QcHardeningTempering from '../pages/QcHardeningTempering';
import QcList from '../pages/QcList';
import QcDetails from '../pages/QcDetails';
import QcPainting from '../pages/QcPainting';
import QcShotPeening from '../pages/QcShotPeening';
import RoleManagement from '../pages/RoleManagement';
import Shift from '../pages/Shift';
import UserRolePermission from '../pages/UserRolePermission';
import WipCutting from '../pages/WipCutting';
import WipDetails from '../pages/WipDetails';
import WipHardeningTempering from '../pages/WipHardeningTempering';
import WipList from '../pages/OrderWipList';
import WipPainting from '../pages/WipPainting';
import WipShotPeening from '../pages/WipShotPeening';
import WorkCenter from '../pages/WorkCenter';
import Production from '../pages/Production';
import ProductionList from '../pages/ProductionList';
import ProductionDetails from '../pages/ProductionDetails';
import ProductionOrder from '../pages/ProductionOrder';
import ProductionOrderList from '../pages/ProductionOrderList';
import ProductionOrderDetails from '../pages/ProductionOrderDetails';
import OrderWipCreate from '../pages/OrderWipCreate';
import WipQc from '../pages/WipQc';
import WipQcList from '../pages/WipQcList';
import OrderWipList from '../pages/OrderWipList';
import OrderWipDetails from '../pages/OrderWipDetails';
import WipQcDetails from '../pages/WipQcDetails';
import WorkCenterDetails from '../pages/WorkCenterDetails';
import OrderWipToDoList from '../pages/OrderWipToDoList';
import WipQcToDoList from '../pages/WipQcToDoList';
import GeneralSetting from '../pages/GeneralSetting';
import Employee from '../pages/Employee';

export function PrivateRoutes() {

	return (
		<Suspense fallback={<FallbackView />}>
			<Switch>
				<Route path='/dashboard' component={Dashboard} />
				<Route path='/setup-expense' component={Expense} />
				<Route path='/setup-work-center-create' component={WorkCenter} />
				<Route path='/setup-shift' component={Shift} />
				<Route path='/setup-operations' component={Operations} />
				<Route path='/bom-create' component={BomCreate} />
				<Route path='/bom-list' component={BomList} />
				<Route path='/bom-details/:id' component={BomDetails} />
				<Route path='/bom-edit/:id' component={BomUpdate} />
				<Route path='/wip-cutting' component={WipCutting} />
				<Route path='/wip-hardening-tempering' component={WipHardeningTempering} />
				<Route path='/wip-shot-peening' component={WipShotPeening} />
				<Route path='/wip-painting' component={WipPainting} />
				<Route path='/wip-qc' component={WipQc} />
				<Route path='/wip-qc-list' component={WipQcList} />
				<Route path='/wip-qc-details/:id' component={WipQcDetails} />
				<Route path='/wip-list' component={WipList} />
				<Route path='/wip-details/:id' component={WipDetails} />
				<Route path='/qc-cutting' component={QcCutting} />
				<Route path='/qc-hardening-tempering' component={QcHardeningTempering} />
				<Route path='/qc-shot-peening' component={QcShotPeening} />
				<Route path='/qc-painting' component={QcPainting} />
				<Route path='/qc-list' component={QcList} />
				<Route path='/qc-details/:id' component={QcDetails} />
				<Route path='/production' component={Production} />
				<Route path='/production-order' component={ProductionOrder} />
				<Route path='/production-order-list' component={ProductionOrderList} />
				<Route path='/production-order-details/:id' component={ProductionOrderDetails} />
				<Route path='/production-list' component={ProductionList} />
				<Route path='/production-details/:id' component={ProductionDetails} />
				<Route path='/company-selection' component={CompanySelection} />
				<Route path='/accesscontrol-rolemanagement' component={RoleManagement} />
				<Route path='/accesscontrol-userrolepermission' component={UserRolePermission} />
				<Route path='/accesscontrol-page-list' component={PageList} />
				<Route path='/accesscontrol-pagemanagement' component={PageManagement} />
				<Route path='/report_daily_production' component={DailyProductionReport} />
				<Route path='/report_production_item_movement' component={ProductionItemMovementReport} />
				<Route path='/order-wip-create' component={OrderWipCreate} />
				<Route path='/order-wip-list' component={OrderWipList} />
				<Route path='/order-wip-details/:id' component={OrderWipDetails} />
				<Route path='/setup-work-center-details/:id' component={WorkCenterDetails} />
				<Route path='/order-wip-to-do-list' component={OrderWipToDoList} />
				<Route path='/wip-qc-to-do-list' component={WipQcToDoList} />
				<Route path='/setup-general-setting' component={GeneralSetting} />
				<Route path='/setup-employee' component={Employee} />
				<Redirect from='/auth' to='/dashboard' />
				<Redirect exact from='/' to='/company-selection' />
				<Redirect to='error/404' />
			</Switch>
		</Suspense>
	)
}
