import React, { useState, useEffect, useContext } from "react";
import { getRequestOptions } from '../components/GetToken'
import { Modal, Button } from 'react-bootstrap'
import { Tab, Tabs } from "react-bootstrap-v5";
import { AppContext } from '../context/AppContext'
import Swal from 'sweetalert2'
import { postRequestOptions } from '../helpers/Fetchwrapper'
import validationErrorMsg from '../helpers/ValidationErrorMsg';

function FinishedGoodsDeliveryModal(props) {
    const initialState = () => ({
        idProductionOrder: '',
        productionItem: '',
        itemCode: '',
        unit: '',
        finishedGoodWarehouse: '',
        productionNo: '',
        batchNo: '',
        productionDate: '',
        expiryDate: '',
        totalCompletedQty: '',
        alreadyDeliveredQty: '',
        deliveryQty: '',
        idUnit: '',
        bu: '',
        idInvItems: '',
        remainingQty: '',
        remarks: '',
        batchNoExpiryDateOnff:  'off',
        otherOverheadCost: '',
    })
    const [formData, setFormData] = useState(initialState());
    const [warehouseOptions, setWarehouseOptions] = useState([]);
    const [reload, setReload] = useState(false);
    const [productionOrderFinishedGoodsList, setProductionOrderFinishedGoodsList] = useState([]);
    const [key, setKey] = useState('finished-good-delivery');
    const { bu } = useContext(AppContext);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(process.env.REACT_APP_API_URL + 'get_warehouse_bu_wise/' + bu, getRequestOptions())
            const json = await response.json()
            setWarehouseOptions(json.data)
        }
        if (bu !== "") {
            fetchData()
        }
    }, [bu])

    
    useEffect(() => {
      // get general setting    
        const fetchData = async () => {

            const response = await fetch(process.env.REACT_APP_API_URL + 'get_general_setting/' + bu, getRequestOptions())
            const json = await response.json()
            if (json.data.length > 0) {
                json.data.map((item) => {
                    if (item.config_key === "batch_no_expiry_date") {
                        setFormData(prevFormData => ({ ...prevFormData, batchNoExpiryDateOnff: item.config_value }))
                    }
                })
            }
        }
        if (bu !== "") {
            fetchData()
        }
    }, [bu])


    useEffect(() => {

        if (props.idProductionOrder !== "") {
            getProductionOrderInfo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.idProductionOrder])

    useEffect(() => {
         const fetchData = async () => {
            const response = await fetch(process.env.REACT_APP_API_URL + 'production_order_finished_goods_list/' + props.idProductionOrder , getRequestOptions())
            const json = await response.json()
            setProductionOrderFinishedGoodsList(json)
            
        
        }
        if (props.idProductionOrder !== "") {
            fetchData();
        }
        
      }, [props.idProductionOrder])
        

    useEffect(() => {
        if (reload === true) {
            getProductionOrderInfo();
            setReload(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])


    // useEffect(() => {
    //     if (formData.productionDate !== "") {
    //         const fetchData = async () => {
    //             const response = await fetch(process.env.REACT_APP_API_URL + 'get_batch_no/' + formData.idProductionOrder + '/' + formData.productionDate, getRequestOptions())
    //             const json = await response.json()
    //             setFormData(prevFormData => ({ ...prevFormData, batchNo: json.data }))
    //         }
    //         fetchData();
    //     }
    // }, [formData.productionDate, formData.idProductionOrder])

    const cancel = () => {
        props.handleClose(false);
    }

    const getProductionOrderInfo = async () => {
        const response = await fetch(process.env.REACT_APP_API_URL + 'get_production_order_info/' + props.idProductionOrder, getRequestOptions())
        const json = await response.json()
        setFormData(prevFormData => (
            { ...prevFormData,
            idProductionOrder: props.idProductionOrder,
            productionItem: json.data.productionItem,
            itemCode: json.data.itemCode,
            unit: json.data.unit,
            finishedGoodWarehouse: json.data.fWarehouseId.toString(),
            productionNo: json.data.productionNo,
            batchNo: '',
            productionDate: '',
            expiryDate: '',
            totalCompletedQty: Number(json.data.completedQty),
            alreadyDeliveredQty: Number(json.data.alreadyDeliveredQty),
            deliveryQty: Number(json.data.remainingQty),
            idUnit: json.data.idUnit,
            bu: bu,
            idInvItems: json.data.idInvItems,
            remainingQty: Number(json.data.remainingQty),
            productionQty : Number(json.data.productionQty)
        }))
    }

    const handleSubmit = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You wan't to deliver this finished good!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.value) {
                const data = { ...formData }
                const requestOptions = postRequestOptions(data)

                fetch(process.env.REACT_APP_API_URL + 'finished_good_delivery_create', requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        if (resp.success === true) {
                            const successMsg = resp.successMessage
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: successMsg,
                            })
                            setReload(true);
                            props.handleClose(false);
                        } else {
                            const errorsMessage = validationErrorMsg(resp.errors).join('<br>')
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                html: errorsMessage,
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error, 'catch the hoop')
                    })
            }
        })
    }

    return (
        <Modal show={props?.show} onHide={() => cancel()} size="lg">
            <Modal.Header closeButton>
                <Modal.Title> Finished Goods Delivery</Modal.Title>
            </Modal.Header>

            <Modal.Body>    
            <Tabs  activeKey={key} onSelect={(k) => setKey(k)}>
                <Tab eventKey="finished-good-delivery" title="Finished Goods Delivery">
                <div className='form-group row'>
                    <label
                        htmlFor='productionItem'
                        className='col-md-4 col-form-label'
                        style={{ textAlign: 'right' }}
                    >
                        Production Item
                    </label>
                    <div className='col-md-6'>
                        <input
                            type='text'
                            name='productionItem'
                            id='productionItem'
                            value={formData.productionItem}
                            className="form-control form-control-sm"
                            readOnly
                        />
                    </div>
                </div>
                <div className='form-group row'>
                    <label
                        htmlFor='itemCode'
                        className='col-md-4 col-form-label'
                        style={{ textAlign: 'right' }}
                    >
                        Item Code
                    </label>
                    <div className='col-md-6'>
                        <input
                            type='text'
                            name='itemCode'
                            id='itemCode'
                            value={formData.itemCode}
                            className="form-control form-control-sm"
                            readOnly
                        />
                    </div>
                </div>
                <div className='form-group row'>
                    <label
                        htmlFor='unit'
                        className='col-md-4 col-form-label'
                        style={{ textAlign: 'right' }}
                    >
                        Unit
                    </label>
                    <div className='col-md-6'>
                        <input
                            type='text'
                            name='unit'
                            id='unit'
                            value={formData.unit}
                            className="form-control form-control-sm"
                            readOnly
                        />
                    </div>
                </div>
                <div className='form-group row' >
                    <label
                        htmlFor='finishedGoodWarehouse'
                        className='col-md-4 col-form-label'
                        style={{ textAlign: 'right' }}
                    >
                        Finished Goods Warehouse <span className='required text-danger'></span>
                    </label>
                    <div className='col-md-6'>
                        <select className='form-control form-control-sm' onChange={(e) => setFormData({ ...formData, finishedGoodWarehouse: e.target.value })} value={formData.finishedGoodWarehouse} disabled>
                            <option value="">Select Warehouse</option>
                            {
                                warehouseOptions.map(function (item, id) {
                                    return <option key={id} value={item.warehouseId}>{item.warehouseName}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className='form-group row'>
                    <label
                        htmlFor='productionNo'
                        className='col-md-4 col-form-label'
                        style={{ textAlign: 'right' }}
                    >
                        Production No
                    </label>
                    <div className='col-md-6'>
                        <input
                            type='text'
                            name='productionNo'
                            id='productionNo'
                            value={formData.productionNo}
                            className="form-control form-control-sm"
                            readOnly
                        />
                    </div>
                </div>

                <div className='form-group row'>
                    <label
                        htmlFor='productionDate'
                        className='col-md-4 col-form-label'
                        style={{ textAlign: 'right' }}
                    >
                        Production Date <span className='required text-danger'></span>
                    </label>
                    <div className='col-md-6'>
                        <input
                            type='date'
                            name='productionDate'
                            id='productionDate'
                            value={formData.productionDate}
                            className="form-control form-control-sm"
                            onChange={(e) => setFormData({ ...formData, productionDate: e.target.value })}
                        />
                    </div>
                </div>
                {formData.batchNoExpiryDateOnff === "on" && <>
                <div className='form-group row'>
                    <label
                        htmlFor='batchNo'
                        className='col-md-4 col-form-label'
                        style={{ textAlign: 'right' }}
                    >
                        Batch No
                    </label>
                    <div className='col-md-6'>
                        <input
                            type='text'
                            name='batchNo'
                            id='batchNo'
                            value={formData.batchNo}
                            className="form-control form-control-sm"
                            onChange={(e) => setFormData({ ...formData, batchNo: e.target.value })}
                        />
                    </div>
                </div>
                <div className='form-group row'>
                    <label
                        htmlFor='expiryDate'
                        className='col-md-4 col-form-label'
                        style={{ textAlign: 'right' }}
                    >
                        Expiry Date <span className='required text-danger'></span>
                    </label>
                    <div className='col-md-6'>
                        <input
                            type='date'
                            name='expiryDate'
                            id='expiryDate'
                            value={formData.expiryDate}
                            className="form-control form-control-sm"
                            onChange={(e) => setFormData({ ...formData, expiryDate: e.target.value })}
                        />
                    </div>
                </div>
                </>
                }
                <div className='form-group row'>
                    <label
                        htmlFor='totalCompletedQty'
                        className='col-md-4 col-form-label'
                        style={{ textAlign: 'right' }}
                    >
                        Total Completed Qty
                    </label>
                    <div className='col-md-6'>
                        <input
                            type='text'
                            name='totalCompletedQty'
                            id='totalCompletedQty'
                            value={formData.totalCompletedQty}
                            className="form-control form-control-sm"
                            readOnly
                        />
                    </div>
                </div>
                <div className='form-group row'>
                    <label
                        htmlFor='alreadyDeliveredQty'
                        className='col-md-4 col-form-label'
                        style={{ textAlign: 'right' }}
                    >
                        Already Delivered Qty
                    </label>
                    <div className='col-md-6'>
                        <input
                            type='text'
                            name='alreadyDeliveredQty'
                            id='alreadyDeliveredQty'
                            value={formData.alreadyDeliveredQty}
                            className="form-control form-control-sm"
                            readOnly
                        />
                    </div>
                </div>
                <div className='form-group row'>
                    <label
                        htmlFor='deliveryQty'
                        className='col-md-4 col-form-label'
                        style={{ textAlign: 'right' }}
                    >
                        Delivery Qty <span className='required text-danger'></span>
                    </label>
                    <div className='col-md-6'>
                        <input
                            type='number'
                            name='deliveryQty'
                            id='deliveryQty'
                            value={formData.deliveryQty}
                            className="form-control form-control-sm"
                            onChange={(e) => setFormData({ ...formData, deliveryQty: e.target.value })}
                        />
                    </div>
                </div>
                <div className='form-group row'>
                    <label htmlFor='otherOverheadCost' className='col-md-4 col-form-label' style={{ textAlign: 'right' }}>
                        Overhead Cost for Delivery Qty
                    </label>
                    <div className='col-md-6'>
                        <input
                            type='number'
                            name='otherOverheadCost'
                            id='otherOverheadCost'
                            value={formData.otherOverheadCost}
                            className="form-control form-control-sm"
                            onChange={(e) => setFormData({ ...formData, otherOverheadCost: e.target.value })}
                        />
                    </div>
                </div>

                    
                <div className='form-group row'>
                    <label
                        htmlFor="remarks"
                        className='col-md-4 col-form-label'
                        style={{ textAlign: 'right' }}
                    >
                        Remarks
                    </label>
                    <div className='col-md-6'>
                        <textarea
                            name="remarks"
                            id="remarks"
                            className="form-control form-control-sm"
                            value={formData.remarks}
                            onChange={(e) => setFormData({ ...formData, remarks: e.target.value })}
                        />
                    </div>
                </div>
                {/* <pre>{JSON.stringify(formData, null, 2)}</pre> */}
             </Tab>
             <Tab eventKey="history" title="History">
             <div className="table-responsive">
             <table className="table table-bordered table-hover" style={{ minWidth: "max-content", marginBottom: "20px" }}>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Delivery Date</th>
                        <th>Delivery Qty</th>
                        <th>Cost Price</th>
                        <th>Batch No</th>
                        <th>Expiry Date</th>
                        <th>Remarks</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {productionOrderFinishedGoodsList.map((item, index) => (
                        <tr key={index}>
                            <td>{item.idFinishedGoods}</td>
                            <td>{item.productonDate}</td>
                            <td>{item.quantity}</td>
                            <td>{item.costPrice}</td>
                            <td>{item.batchNo}</td>
                            <td>{item.expiryDate}</td>
                            <td>{item.remarks}</td>
                            <td>{item.finishedGoodsStatus}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>

             </Tab>  
             </Tabs>
            </Modal.Body>
            <Modal.Footer>
               { key === 'finished-good-delivery' && <button className="btn btn-sm btn-primary me-2" onClick={() => handleSubmit()}>
                    <span>  Submit</span>
                </button> }

                <Button variant='secondary' onClick={() => cancel()} size="sm">
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default FinishedGoodsDeliveryModal;