/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect,useContext, useRef} from 'react'
import {useHistory} from 'react-router-dom'
import Swal from 'sweetalert2'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {KTSVG} from '../../_metronic/helpers'
import {getRequestOptions} from '../components/GetToken'
import { postRequestOptions } from '../helpers/Fetchwrapper'
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import {Tab,Tabs} from "react-bootstrap-v5" ;
import { Redirect } from "react-router-dom";
import usePagePermission from "../components/usePagePermission";
import Error500 from '../components/Error500';
import { minWidth } from '@mui/system'
import { AppContext } from '../context/AppContext'

const customStylesSelect = {
	control: (provided) => ({
		...provided,
		height: 'calc(1.35em + 1.1rem + 2px)',
		minHeight: '35px'
	}),
	valueContainer: (provided) => ({
		...provided,
	})
};


const Production = (props) => {
  const history = useHistory();
  const [productionDate,setProductionDate] = useState('');
  const [rWarehouse, setRwarehouse] = useState('');
  const [fWarehouse, setFwarehouse] = useState('');
  const [warehouseOptions, setWarehouseOptions] = useState([])
  const [product,setProduct] = useState('');
  const [productCode,setProductCode] = useState('');
  const [unit,setUnit] = useState('');
  const [quantity,setQuantity] = useState('');
  const [batchNo,setBatchNo] = useState('');
  const [bomType,setBomType] = useState('production');
  const [bomComponents,setBomComponents] = useState([{item:"",itemCode:"",unit:"",quantity:""}]);
  const [bomOperations,setBomOperations] = useState([{operation:"",step:"",workCenter:"",duration:""}]);
  const [bomItems, setBomItems] = useState([])

  const [remarks,setRemarks] = useState('');
  const [labourCost,setLabourCost] = useState(0);
  const [factoryOverhead,setFactoryOverhead] = useState(0);
  const [rawMaterialCost,setRawMaterialCost] = useState(0);
  const [totalCost,setTotalCost] = useState(0);

  const {bu} = useContext(AppContext);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(process.env.REACT_APP_API_URL + 'get_warehouse_bu_wise/' + bu, getRequestOptions())
      const json = await response.json()
      setWarehouseOptions(json.data)
    }
    if(bu !==""){
    fetchData()
    }
  }, [bu])

  const onChangeQuantity = (e) => {
    setQuantity(e.target.value);
    // update bom components quantity
    let rawMaterialCostSum = 0;
    const newItems = bomItems.map(function (row, rindex) {
      if(row.hasRawMaterial){
      row.operationItems = row.operationItems.map(function (item, index) {
        item.quantity = e.target.value * item.qtyPerBomQty;
        item.cost = (Number(item.price) * Number(item.quantity));
        rawMaterialCostSum = rawMaterialCostSum + Number(item.cost);
        return item;
      })
    }
      return row;
    })
    setRawMaterialCost(rawMaterialCostSum);
    setTotalCost(rawMaterialCostSum + Number(labourCost) + Number(factoryOverhead));
    setBomItems(newItems);

  }





  const hasPermission = usePagePermission('/production');
  //console.log(hasPermission)



  const getProductBom = async(productId) => {

      const response = await fetch(process.env.REACT_APP_API_URL + 'bom_details_by_product_id/' + productId+'/'+rWarehouse, getRequestOptions())
      const res = await response.json();
      const json = res.data;
      let rawMaterialCostSum = 0;

      setRawMaterialCost(0);
      setLabourCost(0);
      setFactoryOverhead(0);
      setTotalCost(0);
      setBomItems([]);

      const bomItems = json.bomoperations.map((row,index)=>{
         
        row.workCenter = {label: row.work_center!==null?row.work_center.workCenterName:'',value:row.work_center!==null?row.work_center.id_work_center:''};
        row.hasRawMaterial  = row.bomcomponents?.length === 0 ?false:true;
        row.operationItems = [{item:"",itemCode:"",unit:"",quantity:""}];
        if(row.hasRawMaterial){
        row.operationItems = row.bomcomponents.map((opi,i)=>{
            opi.item = {...opi.item,itemName:opi.item.itemName,itemId:opi.item.id_inv_items,itemCode:opi.item.itemCode,unitName:opi.item.unit,label:opi.item.itemName,value:opi.item.id_inv_items}
            opi.itemCode = opi.item.itemCode;
            opi.unit = opi.item.unit;
            opi.price = Number(opi.item.price);
            opi.cost = (Number(opi.item.price) * Number(opi.quantity));
            opi.quantity = (Number(opi.quantity)).toFixed(2);
            opi.qtyPerBomQty = (Number(opi.quantity)/Number(json.quantity));

            rawMaterialCostSum = rawMaterialCostSum + Number(opi.cost);
            
            return opi;
        })
       }
        
        return  row;
    })
    console.log(bomItems);
    setRawMaterialCost(rawMaterialCostSum);
    setTotalCost(rawMaterialCostSum + Number(labourCost) + Number(factoryOverhead));
    setBomItems(bomItems);
    setQuantity(json.quantity);

  }

    const getProduct = (inputValue, callback) => {
      if (!inputValue) {
        var api = process.env.REACT_APP_API_URL + "item/manufacturing_item_search";
      } else {
        api = process.env.REACT_APP_API_URL + "item/manufacturing_item_search?q=" + inputValue;
    }

		setTimeout(() => {
			fetch(api,getRequestOptions())
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				const tempArray = resp.data.map(function (element) {
					return { ...element, id: element.itemId, label: `${element.itemName} ${element.itemCode}`, value: element.itemId }
				});
				callback(tempArray);
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});
		});
	  }

 
 

  const handleProductChange = (product) => {
    if(rWarehouse !=""){
      setProduct(product);
      setProductCode(product.itemCode);
      setUnit(product.unitName);
      getProductBom(product.value);
      }
      else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          html: "Please select row material warehouse first.",
        })
      }

  }



	const onComponentsQtyChange = (index,i) => evt => {
    let rawMaterialCostSum = 0;
		const newItems = bomItems.map(function (row, rindex) {
			if (index === rindex) {
				row.operationItems.map((opi,ri)=>{
          if(i===ri){
           opi.quantity = evt.target.value;
           opi.cost = (Number(opi.price) * Number(opi.quantity));
           rawMaterialCostSum = rawMaterialCostSum + Number(opi.cost);
          
          }
        return opi
       })
			}
			return row;
		})

		setBomItems(newItems);
    setRawMaterialCost(rawMaterialCostSum);
    setTotalCost(rawMaterialCostSum + Number(labourCost) + Number(factoryOverhead));

	}

 

  const factoryOverheadChange = (e) => {
    setFactoryOverhead(e.target.value);
    setTotalCost(Number(rawMaterialCost) + Number(labourCost) + Number(e.target.value));

  }

  const labourCostChange = (e) => { 
    setLabourCost(e.target.value);
    setTotalCost(Number(rawMaterialCost) + Number(e.target.value) + Number(factoryOverhead));
  }


  const handleSubmit = (evt) => {
    evt.preventDefault()
    
    var text = 'Are you sure you want to submit?';
    
    Swal.fire({
      title: 'Are you sure?',
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.value) {

        const formData = {
          productionDate : productionDate,
          product: product,
          quantity: quantity,
          remarks: remarks,
          rWarehouse: rWarehouse,
          fWarehouse: fWarehouse,
          bu: bu,
          batchNo: batchNo,
          bomItems: bomItems,
          rawMaterialCost: rawMaterialCost,
          laborCost: labourCost,
          factoryOverhead: factoryOverhead,
          totalCost: totalCost
        }
        
        const requestOptions = postRequestOptions(formData);
        
        var apiEnd = 'production_save'
      
        fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
          .then((resp) => {
            return resp.json()
          })
          .then((resp) => {
            console.log(resp)

            if (resp.success === true) {
             
              var successMsg = ['Production Assembly has been created successfully.']
              

              Swal.fire({
                icon: 'success',
                title: 'Success',
                text: successMsg,
              })

              setProduct('');
              setProductCode('');
              setUnit('');
              setQuantity('');
              setBomType('');
              setBomItems([{
                  operation:"",
                  workCenter:"",
                  duration:"",
                  hasRawMaterial: true,
                  operationItems: [{item:"",itemCode:"",unit:"",quantity:""}]
                }
              ]);
              setBomComponents([{item:"",itemCode:"",unit:"",quantity:""}]);
              setBomOperations([{operation:"",step:"",workCenter:"",duration:""}]);
              setRawMaterialCost(0);
              setLabourCost(0);
              setFactoryOverhead(0);
              setTotalCost(0);
              setRemarks('');
              setBatchNo('');
              setProductionDate('');
              history.push(`/production-details/${resp.data.id}`);

            } else {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                html: resp.errorMessage,
              })
            }
          })
          .catch((error) => {
            console.log(error, 'catch the hoop')
          })
      }
    })
  }

  const tableBody = () => {
    const tbodies = bomItems.map((item, index) => {
      const operationItemsValues = Object.values(item.operationItems)
      const operationItemRows = operationItemsValues.map((opi, i) => {
       
        const slNo = i === 0 ? <td rowSpan={operationItemsValues.length}>{index + 1}</td> : null
        const operationCell =
          i === 0 ? (
            <td  rowSpan={operationItemsValues.length}> {item.operation.operationName}</td>
          ) : null


        return (
          <tr key={i}>
            {slNo}
            {operationCell}
        
              <td>{opi.item.itemName}</td>
              <td>{opi.item.itemCode}</td>
              <td>{opi.item.unitName}</td>
              <td>{opi.item.stock}</td>
              <td>
                {item.hasRawMaterial && 
                 <div className='row'>
                  <div className="col-sm-8"><input className="form-control form-control-sm " type="number" value={opi.quantity} onChange={onComponentsQtyChange(index,i)} /></div>
                 
                </div>
                }
              </td>
              <td>
                {opi.cost}
              </td>
           																
          </tr>
        )
      })
      return operationItemRows
       //return <>{componentsRows}</>
    })

    return tbodies
  }
  


  return (
    <>
    { hasPermission=="1" ?
    <div className='card card-custom'>
        <div className='card-header'>
          <div className='card-title'>
            <KTSVG
              path='/media/icons/duotune/text/txt001.svg'
              className='svg-icon-1 svg-icon-primary'
            />
            <span className='text fw-bolder' style={{marginLeft: '10px'}}>
              Production and Assembly
            </span>
          </div>
        </div>
    <div className='card-body'>
      <div className='ml-12'>
        <form onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-6'>

            <div className='form-group row'>
                <label
                  htmlFor='qcDate'
                  className='col-md-4 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Date <span className='required text-danger'></span>
                </label>
                <div className='col-md-8'>
                  <input
                    type='date'
                    name='productionDate'
                    id='productionDate'
                    value={productionDate}
                    onChange={(e) => setProductionDate(e.target.value)}
                    className='form-control form-control-sm'
                    max={new Date().toISOString().split("T")[0]}
                  />
                </div>
              </div>

              
              <div className='form-group row'>
                <label
                  htmlFor='product'
                  className='col-md-4 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Product <span className='required text-danger'></span>
                </label>
                <div className='col-md-8'>
                <AsyncSelect
                    value={product}
                    name="product"
                    defaultOptions
                    loadOptions={getProduct}
                    placeholder="Select Product"
                    onChange={handleProductChange}
                    styles={customStylesSelect}
                  />
                  
                </div>
              </div>

              <div className='form-group row'>
                <label
                  htmlFor='productCode'
                  className='col-md-4 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Product Code<span className='required text-danger'></span>
                </label>
                <div className='col-md-4'>
                  <input
                    type='text'
                    name='productCode'
                    id='productCode'
                    value={productCode}
                    onChange={(e) => setProductCode(e.target.value)}
                    className="form-control form-control-sm"readOnly
                  />
                </div>
              </div>

              <div className='form-group row'>
                <label
                  htmlFor='unit'
                  className='col-md-4 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Unit<span className='required text-danger'></span>
                </label>
                <div className='col-md-4'>
                  <input
                    type='text'
                    name='unit'
                    id='unit'
                    value={unit}
                    onChange={(e) => setUnit(e.target.value)}
                    className="form-control form-control-sm"readOnly
                  />
                </div>
              </div>

              <div className='form-group row'>
                <label
                  htmlFor='duration'
                  className='col-md-4 col-form-label'
                  style={{textAlign: 'right'}}
                >
                  Quantity <span className='required text-danger'></span>
                </label>
                <div className='col-md-4'>
                  <input
                    type="number"
                    name="quantity"
                    id="quantity"
                    value={quantity}
                    onChange={onChangeQuantity}
                    className="form-control form-control-sm"
                  />
                  </div>
               
              </div>

              
            </div>
            <div className='col-6'>
             
            <div className='form-group row' >
                <label
                  htmlFor='operationName'
                  className='col-md-4 col-form-label'
                  style={{textAlign: 'right'}}
                >
                 Raw Materials Warehouse <span className='required text-danger'></span>
                </label>
                <div className='col-md-8'>
                <select  className='form-control form-control-sm' onChange={(e) => setRwarehouse(e.target.value)}>
                        <option value="">Select Warehouse</option>
                        {
                          warehouseOptions.map(function (item, id) {
                            return <option key={id} value={item.warehouseId}>{item.warehouseName}</option>
                          })
                        }
								</select>
                </div>
              </div> 

              <div className='form-group row' >
                <label
                  htmlFor='operationName'
                  className='col-md-4 col-form-label'
                  style={{textAlign: 'right'}}
                >
                 Finished Product Warehouse <span className='required text-danger'></span>
                </label>
                <div className='col-md-8'>
                <select  className='form-control form-control-sm' onChange={(e) => setFwarehouse(e.target.value)}>
                        <option value="">Select Warehouse</option>
                        {
                          warehouseOptions.map(function (item, id) {
                            return <option key={id} value={item.warehouseId}>{item.warehouseName}</option>
                          })
                        }
								</select>
                </div>
              </div> 
            

              <div className='form-group row'>
                <label htmlFor='batchNo' className='col-md-4 col-form-label' style={{textAlign: 'right'}}>
                  Batch No
                </label>
                <div className='col-md-8'>
                  <input type='text' className='form-control form-control-sm' name='batchNo' id='batchNo' value={batchNo} onChange={(e) => setBatchNo(e.target.value)} />
                </div>
              </div>

              <div className='form-group row'>
                <label htmlFor='remarks' className='col-md-4 col-form-label' style={{textAlign: 'right'}}>
                  Remarks
                </label>
                <div className='col-md-8'>
                  <textarea className='form-control form-control-sm' name='remarks' id='remarks' rows='2' value={remarks} onChange={(e) => setRemarks(e.target.value)}></textarea>
                </div>
                </div>
              
            </div>

            <div className="col-12">
            <Tabs defaultActiveKey="bom-components" >
                <Tab eventKey="bom-components" title="Components">
                    <div className="table-responsive">
                        <table className="table table-bordered table-hover" style={{minWidth: "max-content",marginBottom:"20px"}}>
                            <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Route Stage</th>
                                    <th style={{minWidth:'250px'}}>Item</th>
                                    <th>Code</th>
                                    <th>Unit</th>
                                    <th>Stock</th>
                                    <th>Quantity</th>
                                    <th>Cost</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableBody()}
                            </tbody> 
                        
                        </table>
                        
                    </div>

                    <div className='col-md-6'>
                        <table className='table table-bordered table-hover'>
                          <tbody>
                            <tr>
                                <td>
                                    <label htmlFor="rawMaterialCost">Raw Material Cost</label>
                                </td>
                                <td>
                                    <input type="text" name="rawMaterialCost" id="rawMaterialCost" value={rawMaterialCost}  className="form-control form-control-sm" readOnly/>
                                </td>

                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor="labourCost">Labor  Cost</label>
                                </td>
                                <td>
                                    <input type="text" name="labourCost" id="labourCost" value={labourCost}  className="form-control form-control-sm"  onChange={labourCostChange} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor="factoryOverhead">Factory Overhead</label>
                                    
                                </td>
                                <td>
                                    <input type="text" name="factoryOverhead" id="factoryOverhead" value={factoryOverhead}  className="form-control form-control-sm" onChange={factoryOverheadChange} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor="totalCost">Total Cost</label>
                                </td>
                                <td>
                                    <input type="text" name="totalCost" id="totalCost" value={totalCost}  className="form-control form-control-sm" readOnly/>
                                </td>
                            </tr>
                          </tbody>

                        </table>

                    </div>
                </Tab>
                    
            </Tabs>
            </div>
            
          </div>

          <div className='row'>
            <div className='col-4'></div>
            <div className='col-6' style={{marginBottom:"15px"}}>
              <input
                type='submit'
                className='btn btn-sm btn-success mt-2'
                value="Save"
              />
            </div>
          </div>
          <pre>
          {/* {JSON.stringify(product, null, 2)} */}
        </pre>
        </form>
      </div>
      {/* <ToastContainer /> */}
    </div>
    </div>
    :
    <Error500/>

    }
  </>
  )
}
export default Production;
