/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { KTSVG } from '../../_metronic/helpers'
import { getRequestOptions } from '../components/GetToken'
import { postRequestOptions } from '../helpers/Fetchwrapper'
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Tab, Tabs } from "react-bootstrap-v5";
import { Redirect } from "react-router-dom";
import usePagePermission from "../components/usePagePermission";
import Error500 from '../components/Error500';
import { minWidth } from '@mui/system'
import { AppContext } from '../context/AppContext'
import validationErrorMsg from "../helpers/ValidationErrorMsg"

const customStylesSelect = {
	control: (provided) => ({
		...provided,
		height: 'calc(1.35em + 1.1rem + 2px)',
		minHeight: '35px'
	}),
	valueContainer: (provided) => ({
		...provided,
	})
};


const ProductionOrder = (props) => {
	const history = useHistory();
	const [productionDate, setProductionDate] = useState('');
	const [scheduledDate, setScheduledDate] = useState('');
	const [rWarehouse, setRwarehouse] = useState('');
	const [fWarehouse, setFwarehouse] = useState('');
	const [warehouseOptions, setWarehouseOptions] = useState([])
	const [product, setProduct] = useState('');
	const [productCode, setProductCode] = useState('');
	const [unit, setUnit] = useState('');
	const [quantity, setQuantity] = useState('');
	const [bomType, setBomType] = useState('production');
	const [bomComponents, setBomComponents] = useState([{ item: "", itemCode: "", unit: "", quantity: "" }]);
	const [bomOperations, setBomOperations] = useState([{ operation: "", step: "", workCenter: "", duration: "" }]);
	const [bomItems, setBomItems] = useState([]);
	const [allowModifyProductionOrderBOMOnff, setAllowModifyProductionOrderBOMOnff] = useState('off');


	const [remarks, setRemarks] = useState('');

	const { bu } = useContext(AppContext);

	useEffect(() => {
		const fetchData = async () => {
			const response = await fetch(process.env.REACT_APP_API_URL + 'get_warehouse_bu_wise/' + bu, getRequestOptions())
			const json = await response.json()
			setWarehouseOptions(json.data)
		}
		if (bu !== "") {
			fetchData()
		}
	}, [bu])


	useEffect(() => {
		// get general setting    
		  const fetchData = async () => {
  
			  const response = await fetch(process.env.REACT_APP_API_URL + 'get_general_setting/' + bu, getRequestOptions())
			  const json = await response.json()
			  if (json.data.length > 0) {
				  json.data.map((item) => {
					  if (item.config_key === "allow_modify_production_order_bom") {
						  setAllowModifyProductionOrderBOMOnff(item.config_value)		
					  }
				  })
			  }
		  }
		  if (bu !== "") {
			  fetchData()
		  }
	  }, [bu])

	const onChangeQuantity = (e) => {
		setQuantity(e.target.value);
		// update bom components quantity
		let rawMaterialCostSum = 0;
		const newItems = bomItems.map(function (row, rindex) {
			if (row.hasRawMaterial) {
				row.operationItems = row.operationItems.map(function (item, index) {
					item.quantity = e.target.value * item.qtyPerBomQty;
					item.cost = (Number(item.price) * Number(item.quantity));
					rawMaterialCostSum = rawMaterialCostSum + Number(item.cost);
					return item;
				})
			}
			return row;
		})
		setBomItems(newItems);

	}





	const hasPermission = usePagePermission('/production-order');
	//console.log(hasPermission)



	const getProductBom = async (productId) => {

		const response = await fetch(process.env.REACT_APP_API_URL + 'bom_details_by_product_id/' + productId, getRequestOptions())

		if (response.ok) {
			const res = await response.json();
			const json = res.data;


			setBomItems([]);

			if (json !== null) {
				const bomItems = json.bomoperations.map((row, index) => {

					row.workCenter = { label: row.work_center !== null ? row.work_center.workCenterName : '', value: row.work_center !== null ? row.work_center.id_work_center : '' };
					row.hasRawMaterial = row.bomcomponents?.length === 0 ? false : true;
					row.operationItems = [{ item: "", itemCode: "", unit: "", quantity: "" }];
					row.hasQc = row.hasQc === 'yes' ? true : false;
					if (row.hasRawMaterial) {
						row.operationItems = row.bomcomponents.map((opi, i) => {
							opi.item = { ...opi.item, itemName: opi.item.itemName, itemId: opi.item.id_inv_items, itemCode: opi.item.itemCode, unitName: opi.item.unit, label: opi.item.itemName, value: opi.item.id_inv_items }
							opi.itemCode = opi.item.itemCode;
							opi.unit = opi.item.unit;
							opi.price = Number(opi.item.price);
							opi.cost = (Number(opi.item.price) * Number(opi.quantity));
							opi.quantity = (Number(opi.quantity)).toFixed(2);
							opi.qtyPerBomQty = (Number(opi.quantity) / Number(json.quantity));

							return opi;
						})
					}

					return row;
				})

				setBomItems(bomItems);
				setQuantity(json.quantity);

			}
		}


	}

	const getProduct = (inputValue, callback) => {
		if (!inputValue) {
			var api = process.env.REACT_APP_API_URL + "item/manufacturing_item_search";
		} else {
			api = process.env.REACT_APP_API_URL + "item/manufacturing_item_search?q=" + inputValue;
		}

		setTimeout(() => {
			fetch(api, getRequestOptions())
				.then((resp) => {
					return resp.json()
				})
				.then((resp) => {
					const tempArray = resp.data.map(function (element) {
						return { ...element, id: element.itemId, label: `${element.itemName} ${element.itemCode}`, value: element.itemId }
					});
					callback(tempArray);
				})
				.catch((error) => {
					console.log(error, "catch the hoop")
				});
		});
	}




	const handleProductChange = (product) => {
		setProduct(product);
		setProductCode(product.itemCode);
		setUnit(product.unitName);
		getProductBom(product.value);
	}


	const onComponentsQtyChange = (index, i) => evt => {
		let rawMaterialCostSum = 0;
		const newItems = bomItems.map(function (row, rindex) {
			if (index === rindex) {
				row.operationItems.map((opi, ri) => {
					if (i === ri) {
						opi.quantity = evt.target.value;
						opi.cost = (Number(opi.price) * Number(opi.quantity));
						rawMaterialCostSum = rawMaterialCostSum + Number(opi.cost);

					}
					return opi
				})
			}
			return row;
		})

		setBomItems(newItems);


	}

	const fetchItemData = (inputValue, callback) => {
		if (!inputValue) {
			var api = process.env.REACT_APP_API_URL + "item/item_search";
		} else {
			api = process.env.REACT_APP_API_URL + "item/item_search?q=" + inputValue;
		}

		setTimeout(() => {
			fetch(api,getRequestOptions())
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				const tempArray = resp.data.map(function (element) {
					return { ...element, id: element.itemId, label: `${element.itemName} ${element.itemCode}`, value: element.itemId }
				});
				callback(tempArray);
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});
		});
	}

	const addBomOperationItem = (index) => () =>{
    
		const newItems = bomItems.map(function (row, rindex) {
	
		  if (index === rindex) {
			// Assuming the last item in operationItems array contains the id_bom to be used
			const lastItem = row.operationItems[row.operationItems.length - 1];
            const idBomValue = lastItem ? lastItem.id_bom : null;
            const idBomOperationsValue = lastItem ? lastItem.id_bom_operations : null;

			row.operationItems = row.operationItems.concat([{
                item: "",
                itemCode: "",
                unit: "",
                quantity: "",
                id_bom: idBomValue, // Use the retrieved id_bom value
                id_bom_operations: idBomOperationsValue, // Use the retrieved id_bom_operations value
            }]);
	
			console.log(row.operationItems)
		   
		  }
		  return row
		})
		
		setBomItems(newItems);
	
	  }
	
	  const  removeBomOperationItem = (index,itemIndex) => () => {
		console.log(index,itemIndex)
		const newItems = bomItems.map((row,rindex) => {
			if(index === rindex){
			  row.operationItems =  row.operationItems.filter((opi,ri) =>  itemIndex !== ri);
			}
			return row;
		})
		setBomItems(newItems);
	  }

	  const onOperationItemChange = (index,i) => item => {

		const newItems = bomItems.map(function (row, rindex) {
		  if (index === rindex) {
			 row.operationItems.map((opi,ri)=>{
			   if(i===ri){
				opi.item = item;
				opi.unit = item.unitName;
				opi.itemCode = item.itemCode;
	
			   }
			 return opi
			})
		   
		  }
		  return row
		})
		
		setBomItems(newItems)
	
		}
	


	const onChangeFromWarehouse = (index, i) => evt => {

		const newItems = bomItems.map(function (row, rindex) {
			if (index === rindex) {
				row.operationItems.map((opi, ri) => {
					if (i === ri) {
						opi.fromWarehouse = evt.target.value;
					}
					return opi
				})
			}
			return row;
		})

		setBomItems(newItems);


		let firstItemIndexWithFromWarehouseSet = null;

		newItems.forEach((row, rowIndex) => {
			if (row.operationItems) {
				row.operationItems.forEach((opi, opiIndex) => {
					if ((opi.fromWarehouse !== undefined && opi.fromWarehouse !== "") && firstItemIndexWithFromWarehouseSet === null) {
						firstItemIndexWithFromWarehouseSet = {
							rowIndex,
							opiIndex,
						};
					}
				});
			}
		});


		// then set the rest of the items to the same warehouse
		if (firstItemIndexWithFromWarehouseSet !== null) {
			const { rowIndex, opiIndex } = firstItemIndexWithFromWarehouseSet;

			if (index <= rowIndex && i <= opiIndex) {

				const warehouseValue = newItems[rowIndex].operationItems[opiIndex].fromWarehouse;

				newItems.forEach((row, rIndex) => {
					if (row.operationItems) {
						row.operationItems.forEach((opi, oIndex) => {
							opi.fromWarehouse = warehouseValue;
						});
					}
				});

				setBomItems(newItems);
			}
		}



	}



	const onDurationChange = (index) => evt => {
		const newItems = bomItems.map(function (row, rindex) {
			if (index === rindex) {
				row.duration = evt.target.value;
			}
			return row;
		})
		setBomItems(newItems);
	}

	const handleQcCheck = (event) => {

		const newItems = bomItems.map((row, index) => {
			if (index == event.target.value) {
				row.hasQc = event.target.checked;
			}
			return row;
		}
		);
		setBomItems(newItems);
	}



	const handleSubmit = (evt) => {
		evt.preventDefault()

		var text = 'Are you sure you want to submit?';

		Swal.fire({
			title: 'Are you sure?',
			text: text,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes',
			cancelButtonText: 'No',
		}).then((result) => {
			if (result.value) {

				const formData = {
					scheduledDate: scheduledDate,
					product: product,
					quantity: quantity,
					remarks: remarks,
					fWarehouse: fWarehouse,
					bu: bu,
					bomItems: bomItems
				}

				const requestOptions = postRequestOptions(formData);

				var apiEnd = 'production_order_save'

				fetch(process.env.REACT_APP_API_URL + apiEnd, requestOptions)
					.then((resp) => {
						return resp.json()
					})
					.then((resp) => {
						console.log(resp)

						if (resp.success === true) {

							var successMsg = ['Production Order has been created successfully.']


							Swal.fire({
								icon: 'success',
								title: 'Success',
								text: successMsg,
							})

							setProduct('');
							setProductCode('');
							setUnit('');
							setQuantity('');
							setBomType('');
							setBomItems([{
								operation: "",
								workCenter: "",
								duration: "",
								hasRawMaterial: true,
								operationItems: [{ item: "", itemCode: "", unit: "", quantity: "" }]
							}
							]);
							setBomComponents([{ item: "", itemCode: "", unit: "", quantity: "" }]);
							setBomOperations([{ operation: "", step: "", workCenter: "", duration: "" }]);
							setRemarks('');
							setProductionDate('');
							history.push(`/production-order-details/${resp.data.id}`);

						} else {

							const errorsMessage = validationErrorMsg(resp.errors).join("<br>");
							Swal.fire({
								icon: 'error',
								title: 'Error',
								html: errorsMessage,
							})
						}
					})
					.catch((error) => {
						console.log(error, 'catch the hoop')
					})
			}
		})
	}

	const tableBody = () => {
		const tbodies = bomItems.map((item, index) => {
			const operationItemsValues = Object.values(item.operationItems)
			const operationItemRows = operationItemsValues.map((opi, i) => {

				const slNo = i === 0 ? <td rowSpan={operationItemsValues.length}>{index + 1}</td> : null
				const operationCell =
					i === 0 ? (
						<td rowSpan={operationItemsValues.length}> {item.operation.operationName}</td>
					) : null

				const workCenterCell = i === 0 ? (
					<td rowSpan={operationItemsValues.length}> {item.workCenter.label}</td>
				) : null


				const durationCell = i === 0 ? (
					<td rowSpan={operationItemsValues.length}> <input className='form-control form-control-sm' type='number' value={item.duration} onChange={onDurationChange(index)} /></td>
				) : null

				const hasQcCell = i === 0 ? <td rowSpan={operationItemsValues.length}><input className='form-check-input' type='checkbox' value={index} key={index} onChange={handleQcCheck} checked={item.hasQc} /></td> : null


				return (
					<tr key={i}>
						{slNo}
						{operationCell}
						{workCenterCell}
						<td>
							{opi.item != null && opi.item.itemName ? (
								opi.item.itemName
							) : (
								item.hasRawMaterial && (opi.item == null || opi.item === "") && (
									<AsyncSelect
										key={index}
										value={opi.item}
										defaultOptions
										loadOptions={fetchItemData}
										placeholder="Select Item"
										onChange={onOperationItemChange(index, i)}
										styles={customStylesSelect}
									/>
								)
							)}
						</td>

						<td>{opi.item.itemCode}</td>
						<td>{opi.item.unitName}</td>
						<td>
							{item.hasRawMaterial &&
								<div className='row'>
									<div className="col-sm-8"><input className="form-control form-control-sm " type="number" value={opi.quantity} onChange={onComponentsQtyChange(index, i)} />
									</div>
									{ allowModifyProductionOrderBOMOnff =='on' && <div className="col-sm-4">  { i===(item.operationItems.length-1)  && <button type="button" className="btn btn-light btn-sm" onClick={addBomOperationItem(index)}><i className="fa fa-plus"></i></button> }
                  
									<button type='button' className='btn btn-icon btn-light btn-active-light-danger  btn-sm' onClick={removeBomOperationItem(index,i)}>
										<span className='svg-icon svg-icon-md svg-icon-danger'>
										<KTSVG path={'/media/svg/icons/General/Trash.svg'} />
										</span>
									</button>
									</div>
									}
								</div>
							}
						</td>
						<td>
						{item.hasRawMaterial && <><select className='form-control form-control-sm' onChange={onChangeFromWarehouse(index, i)} value={opi.fromWarehouse}>
								<option value="">Select Warehouse</option>
								{
									warehouseOptions.map(function (item, id) {
										return <option key={id} value={item.warehouseId}>{item.warehouseName}</option>
									})
								}
							</select>
							</>
							}
						</td>

						
						{hasQcCell}
						{durationCell}


					</tr>
				)
			})
			return operationItemRows
			//return <>{componentsRows}</>
		})

		return tbodies
	}



	return (
		<>
			{hasPermission == "1" ?
				<div className='card card-custom'>
					<div className='card-header'>
						<div className='card-title'>
							<KTSVG
								path='/media/icons/duotune/text/txt001.svg'
								className='svg-icon-1 svg-icon-primary'
							/>
							<span className='text fw-bolder' style={{ marginLeft: '10px' }}>
								Production Order
							</span>
						</div>
					</div>
					<div className='card-body'>
						<div className='ml-12'>
							<form onSubmit={handleSubmit}>
								<div className='row'>
									<div className='col-6'>

										<div className='form-group row'>
											<label
												htmlFor='product'
												className='col-md-4 col-form-label'
												style={{ textAlign: 'right' }}
											>
												Product <span className='required text-danger'></span>
											</label>
											<div className='col-md-8'>
												<AsyncSelect
													value={product}
													name="product"
													defaultOptions
													loadOptions={getProduct}
													placeholder="Select Product"
													onChange={handleProductChange}
													styles={customStylesSelect}
												/>

											</div>
										</div>

										<div className='form-group row'>
											<label
												htmlFor='productCode'
												className='col-md-4 col-form-label'
												style={{ textAlign: 'right' }}
											>
												Product Code<span className='required text-danger'></span>
											</label>
											<div className='col-md-4'>
												<input
													type='text'
													name='productCode'
													id='productCode'
													value={productCode}
													onChange={(e) => setProductCode(e.target.value)}
													className="form-control form-control-sm" readOnly
												/>
											</div>
										</div>

										<div className='form-group row'>
											<label
												htmlFor='unit'
												className='col-md-4 col-form-label'
												style={{ textAlign: 'right' }}
											>
												Unit<span className='required text-danger'></span>
											</label>
											<div className='col-md-4'>
												<input
													type='text'
													name='unit'
													id='unit'
													value={unit}
													onChange={(e) => setUnit(e.target.value)}
													className="form-control form-control-sm" readOnly
												/>
											</div>
										</div>

										<div className='form-group row'>
											<label
												htmlFor='duration'
												className='col-md-4 col-form-label'
												style={{ textAlign: 'right' }}
											>
												Quantity <span className='required text-danger'></span>
											</label>
											<div className='col-md-4'>
												<input
													type="number"
													name="quantity"
													id="quantity"
													value={quantity}
													onChange={onChangeQuantity}
													className="form-control form-control-sm"
												/>
											</div>

										</div>


									</div>
									<div className='col-6'>

										<div className='form-group row'>
											<label
												htmlFor='scheduledDate'
												className='col-md-4 col-form-label'
												style={{ textAlign: 'right' }}
											>
												Scheduled Date <span className='required text-danger'></span>
											</label>
											<div className='col-md-8'>
												<input
													type='date'
													name='scheduledDate'
													id='scheduledDate'
													value={scheduledDate}
													onChange={(e) => setScheduledDate(e.target.value)}
													className='form-control form-control-sm'

												/>
											</div>
										</div>

										<div className='form-group row' >
											<label
												htmlFor='operationName'
												className='col-md-4 col-form-label'
												style={{ textAlign: 'right' }}
											>
												Finished Product Warehouse <span className='required text-danger'></span>
											</label>
											<div className='col-md-8'>
												<select className='form-control form-control-sm' onChange={(e) => setFwarehouse(e.target.value)}>
													<option value="">Select Warehouse</option>
													{
														warehouseOptions.map(function (item, id) {
															return <option key={id} value={item.warehouseId}>{item.warehouseName}</option>
														})
													}
												</select>
											</div>
										</div>



										<div className='form-group row'>
											<label htmlFor='remarks' className='col-md-4 col-form-label' style={{ textAlign: 'right' }}>
												Remarks
											</label>
											<div className='col-md-8'>
												<textarea className='form-control form-control-sm' name='remarks' id='remarks' rows='2' value={remarks} onChange={(e) => setRemarks(e.target.value)}></textarea>
											</div>
										</div>

									</div>

									<div className="col-12">
										<Tabs defaultActiveKey="bom-components" >
											<Tab eventKey="bom-components" title="Components">
												<div className="table-responsive">
													<table className="table table-bordered table-hover" style={{ minWidth: "max-content", marginBottom: "20px" }}>
														<thead>
															<tr>
																<th>SL</th>
																<th>Route Stage</th>
																<th>Work Center</th>
																<th style={{ minWidth: '250px' }}>Item</th>
																<th>Code</th>
																<th>Unit</th>
																<th>Quantity</th>
																<th>Raw Materials Warehouse</th>
																<th>Has QC</th>
																<th>Duration(minutes)</th>
															</tr>
														</thead>
														<tbody>
															{tableBody()}
														</tbody>

													</table>

												</div>


											</Tab>

										</Tabs>
									</div>

								</div>

								<div className='row'>
									<div className='col-2'></div>
									<div className='col-6' style={{ marginBottom: "15px" }}>
										<input
											type='submit'
											className='btn btn-sm btn-success mt-2'
											value="Save"
										/>
									</div>
								</div>
								{/* <pre>
									{JSON.stringify(bomItems, null, 2)}
								</pre> */}
							</form>
						</div>
						{/* <ToastContainer /> */}
					</div>
				</div>
				:
				<Error500 />

			}
		</>
	)
}
export default ProductionOrder;
