/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { getRequestOptions } from '../components/GetToken'
import { KTSVG } from '../../_metronic/helpers'
import Swal from 'sweetalert2'
import { postRequestOptions } from '../helpers/Fetchwrapper'
import validationErrorMsg from '../helpers/ValidationErrorMsg';
import { useHistory } from 'react-router-dom'
import { Modal, Button } from 'react-bootstrap'
import AsyncSelect from 'react-select/async'

const customStylesSelect = {
	control: (provided) => ({
		...provided,
		height: 'calc(1.35em + 1.1rem + 2px)',
		minHeight: '35px'
	}),
	valueContainer: (provided) => ({
		...provided,
	})
};

function WipWorkingDurationModal(props) {
    const history = useHistory();
    const [poNo, setPoNo] = useState('');
    const [poQty, setPoQty] = useState('');
    const [wipDate, setWipDate] = useState('');
    const [completedQty, setCompletedQty] = useState('');
    const [remainingQty, setRemainingQty] = useState('');
    const [productionQty, setProductionQty] = useState('');
    const [bomDuration, setBomDuration] = useState('');
    const [timeKeeping, setTimeKeeping] = useState('');
    const [employeeSheetTracking, setEmployeeSheetTracking] = useState('');
    const [workCenterName, setWorkCenterName] = useState('');
    const [idWorkCenter, setIdWorkCenter] = useState('');
    const [damageQty, setDamageQty] = useState('');
    const [detailsTiming, setDetailsTiming] = useState([
        {
            startDateTime: '',
            endDateTime: '',
            duration: ''
        }
    ]);
    const [employeeSheet , setEmployeeSheet] = useState([
            { employee: '',startDateTime: '', endDateTime: '', duration: '' } 
    ]);
    const [actualDuration, setActualDuration] = useState('');
    const [componentDetails, setComponentDetails] = useState([]);

    useEffect(() => {
        if (props.modalData !== '') {
            setPoNo(props.modalData.productionOrderNo);
            setPoQty(props.modalData.poQty);
            setCompletedQty(props.modalData.completedQty);
            setRemainingQty(props.modalData.remainingQty);
            setBomDuration(props.modalData.duration);
            setProductionQty(props.modalData.remainingQty);
            setWorkCenterName(props.modalData.workCenterName);
            setIdWorkCenter(props.modalData.idWorkCenter);
            setDamageQty(props.modalData.damageQty);
            getWipDetails();
        }

    }, [props.modalData]);

    useEffect(() => {
        if (detailsTiming.length > 0) {
            const totalDuration = objPsum(detailsTiming, 'duration');
            setActualDuration(totalDuration);
        }
      
        
    }, [detailsTiming]);

    useEffect(() => {
        if (employeeSheet.length > 0) {
            const totalDuration = objPsum(employeeSheet, 'duration');
            setActualDuration(totalDuration);
        }
    }, [employeeSheet]);    

    const getWipDetails = () => {
        fetch(`${process.env.REACT_APP_API_URL}get_wip_details/` + props.modalData.idProductionOrder + '/' + props.operation.value, getRequestOptions())

            .then((resp) => {
                return resp.json()
            }
            )
            .then((resp) => {
                const newComponentDetails = resp.data.map((item) => {
                    item.bomQty = item.originalBomQty * props.modalData.remainingQty;
                    item.actualQty = item.bomQty;
                    return item;
                });
                setComponentDetails(newComponentDetails);

            })
    }

    const handleProductionQtyChange = (e) => {
        const newComponentDetails = [...componentDetails];

        setProductionQty(e.target.value);
        newComponentDetails.map((item) => {
            item.bomQty = item.originalBomQty * e.target.value;
            item.actualQty = item.bomQty;
            return item;
        });
        setComponentDetails(newComponentDetails);

    }

    const handleActualQtyChange = (index) => (e) => {
        const newComponentDetails = [...componentDetails];
        if (e.target.value > Number(newComponentDetails[index].bomQty)) {
            alert('Actual Qty cannot be more than BOM Qty');
            e.target.value = '';
        }
        else {
            newComponentDetails[index].actualQty = e.target.value;
            setComponentDetails(newComponentDetails);
        }
    }

    const handleTimeKeepingChange = (e) => {
        setTimeKeeping(e.target.checked);
        setActualDuration('');
        setDetailsTiming([
            {
                startDateTime: '',
                endDateTime: '',
                duration: ''
            }
        ]);
    }


    const handleEmployeeSheetTrackingChange = (e) => {
        setEmployeeSheetTracking(e.target.checked);
        setActualDuration("");
        setEmployeeSheet([
            {
                employee: "",
                startDateTime: "",
                endDateTime: "",
                duration: ""
            }
        ]);
      }



    const handleEmployeeSheetStartDateTimeChange = (index) => (e) => {
        const newEmployeeSheet = [...employeeSheet];
        newEmployeeSheet[index].startDateTime = e.target.value;
        newEmployeeSheet[index].duration = calculateDuration(newEmployeeSheet[index].startDateTime, newEmployeeSheet[index].endDateTime);
        setEmployeeSheet(newEmployeeSheet);
        console.log(newEmployeeSheet);
    }


    const handleEndDateTImeChange = (index) => (e) => {
        const newDetailsTiming = [...detailsTiming];
        newDetailsTiming[index].endDateTime = e.target.value;
        newDetailsTiming[index].duration = calculateDuration(newDetailsTiming[index].startDateTime, newDetailsTiming[index].endDateTime);
        setDetailsTiming(newDetailsTiming);
    }

    const handleEmployeeSheetEndDateTImeChange = (index) => (e) => {
        const newEmployeeSheet = [...employeeSheet];
        newEmployeeSheet[index].endDateTime = e.target.value;
        newEmployeeSheet[index].duration = calculateDuration(newEmployeeSheet[index].startDateTime, newEmployeeSheet[index].endDateTime);
        setEmployeeSheet(newEmployeeSheet);
    }


    const calculateDuration = (startDateTime, endDateTime) => {
        if (startDateTime === '' || endDateTime === '') {
            return '';
        }
        const start = new Date(startDateTime);
        const end = new Date(endDateTime);
        const diff = Math.abs(end - start);
        const minutes = Math.floor((diff / 1000) / 60);
        return minutes;
    }

    const addTiming = () => {
        setDetailsTiming([...detailsTiming, {
            startDateTime: '',
            endDateTime: '',
            duration: ''
        }]);
    }

    const removeTiming = (index) => () => {
        const newDetailsTiming = [...detailsTiming];
        newDetailsTiming.splice(index, 1);
        setDetailsTiming(newDetailsTiming);
    }


    const addEmployeeShhettTiming = () => {
        setEmployeeSheet([...employeeSheet, {
            employee: '',
            startDateTime: '',
            endDateTime: '',
            duration: ''
        }]);
    }

    const removeEmployeeSheetTiming = (index) => () => {
        const newEmployeeSheet = [...employeeSheet];
        newEmployeeSheet.splice(index, 1);
        setEmployeeSheet(newEmployeeSheet);
    }
    


    const objPsum = (items, prop) => {
        return items.reduce(function (a, b) {
            return Number(a) + Number(b[prop]);
        }, 0);
    }

    const loadOptions = (inputValue, callback) => {
        fetch(`${process.env.REACT_APP_API_URL}production_employee_search?q=` + inputValue, getRequestOptions())
            .then((resp) => {
                return resp.json()
            }
            )
            .then((resp) => {
                const options = resp.data.map((item) => {      
                    return {...item, value: item.id, label:  `${item.employeeName} - ${item.employeeCode}` };

                });
                callback(options);
            })
    }

    const handleEmployeeChange = (index) => item => {
        const newEmployeeSheet = [...employeeSheet];
        newEmployeeSheet[index].employee = item;
        setEmployeeSheet(newEmployeeSheet);
    }


    const cancel = () => {
        props.handleClose(false);
    }

    const handleSubmit = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You wan't to create this wip!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.value) {
                const formData = {
                    idProductionOrder: props.modalData.idProductionOrder,
                    idOperations: props.operation.value,
                    wipDate: wipDate,
                    bomDuration: bomDuration,
                    remainingQty: remainingQty,
                    productionQty: productionQty,
                    actualDuration: actualDuration,
                    componentDetails: componentDetails,
                    timeKeeping: timeKeeping,
                    employeeSheetTracking: employeeSheetTracking,
                    detailsTiming: detailsTiming,
                    employeeSheet: employeeSheet,
                    workCenterName: workCenterName,
                    idWorkCenter: idWorkCenter,
                    bu: props.bu,
                    orderNo: poNo,
                    operationName: props.operation.label,
                    poQty: poQty,
                    completedQty: completedQty,
                    damageQty: damageQty

                }
                const requestOptions = postRequestOptions(formData)

                fetch(process.env.REACT_APP_API_URL + 'wip_create', requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        if (resp.success === true) {
                            const successMsg = resp.successMessage
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: successMsg,
                            })
                            history.push(`/order-wip-details/${resp.data.id}`);
                        } else {
                            const errorsMessage = validationErrorMsg(resp.errors).join('<br>')
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                html: errorsMessage,
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error, 'catch the hoop')
                    })
            }
        })
    }

    return (
        <>
            <Modal show={props?.show} onHide={() => cancel()} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title> Work In Progress - {props?.operation?.label}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className='form-group row'>
                        <label
                            htmlFor='poNo'
                            className='col-md-4 col-form-label'
                            style={{ textAlign: 'right' }}
                        >
                            Order No<span className='required text-danger'></span>
                        </label>
                        <div className='col-md-6'>
                            <input
                                type='text'
                                name='poNo'
                                id='poNo'
                                value={poNo}
                                className="form-control form-control-sm"
                                readOnly
                            />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label
                            htmlFor='workCenterName'
                            className='col-md-4 col-form-label'
                            style={{ textAlign: 'right' }}
                        >
                            Work Center<span className='required text-danger'></span>
                        </label>
                        <div className='col-md-6'>
                            <input
                                type='text'
                                name='workCenterName'
                                id='workCenterName'
                                value={workCenterName}
                                className="form-control form-control-sm"
                                readOnly
                            />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label
                            htmlFor='poQty'
                            className='col-md-4 col-form-label'
                            style={{ textAlign: 'right' }}
                        >
                            PO Qty<span className='required text-danger'></span>
                        </label>
                        <div className='col-md-6'>
                            <input
                                type='text'
                                name='poQty'
                                id='poQty'
                                value={poQty}
                                className="form-control form-control-sm"
                                readOnly
                            />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label
                            htmlFor='completedQty'
                            className='col-md-4 col-form-label'
                            style={{ textAlign: 'right' }}
                        >
                            Completed Qty<span className='required text-danger'></span>
                        </label>
                        <div className='col-md-6'>
                            <input
                                type='text'
                                name='completedQty'
                                id='completedQty'
                                value={completedQty}
                                className="form-control form-control-sm"
                                readOnly
                            />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label
                            htmlFor='damageQty'
                            className='col-md-4 col-form-label'
                            style={{ textAlign: 'right' }}
                        >
                            Damage Qty<span className='required text-danger'></span>
                        </label>
                        <div className='col-md-6'>
                            <input
                                type='text'
                                name='damageQty'
                                id='damageQty'
                                value={damageQty}
                                className="form-control form-control-sm"
                                readOnly
                            />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label
                            htmlFor='remainingQty'
                            className='col-md-4 col-form-label'
                            style={{ textAlign: 'right' }}
                        >
                            Remaining Qty<span className='required text-danger'></span>
                        </label>
                        <div className='col-md-6'>
                            <input
                                type='text'
                                name='remainingQty'
                                id='remainingQty'
                                value={remainingQty}
                                className="form-control form-control-sm"
                                readOnly
                            />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label
                            htmlFor='productionQty'
                            className='col-md-4 col-form-label'
                            style={{ textAlign: 'right' }}
                        >
                            Production Qty<span className='required text-danger'></span>
                        </label>
                        <div className='col-md-6'>
                            <input
                                type='text'
                                name='productionQty'
                                id='productionQty'
                                value={productionQty}
                                className="form-control form-control-sm"
                                onChange={handleProductionQtyChange}
                                max={remainingQty}
                            />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label
                            htmlFor='wipDate'
                            className='col-md-4 col-form-label'
                            style={{ textAlign: 'right' }}
                        >
                            Work-in-progress Date<span className='required text-danger'></span>
                        </label>
                        <div className='col-md-6'>
                            <input
                                type='date'
                                name='wipDate'
                                id='wipDate'
                                value={wipDate}
                                className="form-control form-control-sm"
                                onChange={(e) => setWipDate(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label
                            htmlFor='bomDuration'
                            className='col-md-4 col-form-label'
                            style={{ textAlign: 'right' }}
                        >
                            Bom Duration(Minutes)<span className='required text-danger'></span>
                        </label>
                        <div className='col-md-6'>
                            <input
                                type='text'
                                name='bomDuration'
                                id='bomDuration'
                                value={bomDuration}
                                className="form-control form-control-sm"
                                readOnly
                            />
                        </div>
                    </div>
                    

                   <div className="form-group row">
                        <div className='form-check form-check-custom form-check-solid mt-2'>

                            <span >
                                <input className='form-check-input' type='checkbox' value={employeeSheetTracking} checked={employeeSheetTracking}  onChange={handleEmployeeSheetTrackingChange} />
                            </span>
                            <label htmlFor="timeKeeping" className="col-md-8 col-form-label" style={{ textAlign: "left", marginLeft: "5px" }} > Employee  Sheet </label>
                        </div>

                    </div>
                    {
                        employeeSheetTracking &&
                        <div className="table-responsive m-5">
                             
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Sl</th>
                                        <th style={{minWidth:'210px'}}>Employee</th>
                                        <th>Start Time</th>
                                        <th>End Time</th>
                                        <th>Duration ( Minutes)</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        employeeSheet.length > 0 && employeeSheet.map((item, index) =>
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                <AsyncSelect key={index}
                                                 value={item.employee}  
                                                 defaultOptions
                                                 loadOptions={loadOptions} 
                                                 placeholder="Select Employee" 
                                                 onChange={handleEmployeeChange(index)} 
                                                 styles={customStylesSelect} 
                                                 menuPosition="fixed"
                                                 />
                                                </td>
                                                <td>
                                                    <input type='datetime-local' name='startDateTime' value={item.startDateTime} id='startDateTime' className="form-control form-control-sm" onChange={handleEmployeeSheetStartDateTimeChange(index)} />
                                                </td>
                                                <td>
                                                    <input type='datetime-local' name='endDateTime' value={item.endDateTime} id='endDateTime' className="form-control form-control-sm" onChange={handleEmployeeSheetEndDateTImeChange(index)} />
                                                </td>
                                                <td>
                                                    <input type='text' name='duration' id='duration' value={item.duration} className="form-control form-control-sm" readOnly />
                                                </td>
                                                <td style={{ whiteSpace: "nowrap" }}>
                                                    {index === (employeeSheet.length - 1) &&
                                                        <button type="button" className="btn btn-icon btn-light btn-active-light-primary  btn-sm me-1" onClick={addEmployeeShhettTiming}>
                                                            <KTSVG path='/media/svg/icons/Navigation/Plus.svg' className='svg-icon svg-icon-5' />
                                                        </button>
                                                    }
                                                    {employeeSheet.length > 1 &&
                                                        <button type="button" className="btn btn-icon btn-light btn-active-light-danger btn-sm" onClick={removeEmployeeSheetTiming(index)}>
                                                            <KTSVG path='/media/svg/icons/General/Trash.svg' className='svg-icon svg-icon-5' />
                                                        </button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>


                            </table>
                        </div>
                    }

                <div className='form-group row'>
                        <label
                            htmlFor='actualDuration'
                            className='col-md-4 col-form-label'
                            style={{ textAlign: 'right' }}
                        >
                            Actual Duration (Minutes)<span className='required text-danger'></span>
                        </label>
                        <div className='col-md-6'>
                            <input
                                type='number'
                                name='actualDuration'
                                id='actualDuration'
                                value={actualDuration}
                                className="form-control form-control-sm"
                                onChange={(e) => setActualDuration(e.target.value)}
                                readOnly={(timeKeeping || employeeSheetTracking )? true : false}
                            />
                        </div>
                </div>
                 
                    
                    <div className="table-responsive m-5">
                        <table className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>SN</th>
                                    <th>Componenet/ Raw Material</th>
                                    <th>Code</th>
                                    <th>Unit</th>
                                    <th>BOM Qty</th>
                                    <th>Issue Qty</th>
                                    <th>Actual Qty</th>
                                    {/* <th>Issue Rate</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    componentDetails.length > 0 && componentDetails.map((item, index) =>
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.itemName}</td>
                                            <td>{item.itemCode}</td>
                                            <td>{item.unitName}</td>
                                            <td style={{ textAlign: "right" }}>{item.bomQty}</td>
                                            <td style={{ textAlign: "right" }}>{item.issuedQty}</td>
                                            <td>
                                                <input type='number' name='actualQty' id='actualQty' value={item.actualQty} className="form-control form-control-sm" onChange={handleActualQtyChange(index)} readOnly />
                                            </td>
                                            {/* <td>{item.issueRate}</td> */}
                                        </tr>
                                    )
                                }

                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-sm btn-primary me-2" onClick={() => handleSubmit()}>
                        <span>  Submit</span>
                    </button>

                    <Button variant='secondary' onClick={() => cancel()} size="sm">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default WipWorkingDurationModal;