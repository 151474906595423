import React, { useState, useEffect } from "react";
import { getRequestOptions } from '../components/GetToken'
import { Modal, Button } from 'react-bootstrap'

function MrrListModal(props) {
    const [mrrList, setMrrList] = useState([]);

    useEffect(() => {
        const getMrrList = () => {
            fetch(`${process.env.REACT_APP_API_URL}get_mrr_list_for_item/` + props.stockOutDetailsId, getRequestOptions())
                .then((resp) => {
                    return resp.json()
                })
                .then((resp) => {
                    setMrrList(resp.data);
                })
        }
        if (props.stockOutDetailsId !== null) {
            getMrrList();
        }
    }, [props.stockOutDetailsId]);

    const cancel = () => {
        props.handleClose(false);
    }

    return (
        <Modal show={props?.show} onHide={() => cancel()} size="lg">
            <Modal.Header closeButton>
                <Modal.Title> Mrr List</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="table-responsive m-2">
                    <table className="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>Sl</th>
                                <th>Mrr No</th>
                                <th>Mrr Date</th>
                                <th>Vendor Name</th>
                                <th>Address</th>
                                <th>Contact</th>
                                <th>Quantity</th>
                                <th>Rate</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                mrrList.length > 0 && mrrList.map((item, index) =>
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.mrr_no}</td>
                                        <td>{item.mrr_date}</td>
                                        <td>{item.vendor_name}</td>
                                        <td>{item.vendor_address}</td>
                                        <td>{item.vendor_contact_person_phone}</td>
                                        <td>{Number(item.quantity).toFixed(4)}</td>
                                        <td>{Number(item.rate).toFixed(4)}</td>
                                        <td>{Number(item.amount).toFixed(4)}</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={() => cancel()} size="sm">
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default MrrListModal;