import React, { useState } from 'react'
import DataTable from '../components/DtTable'
import { KTSVG } from '../../_metronic/helpers'
import ShiftCreate from '../components/ShiftCreate'


export const Shift = () => {

  const [newData, setNewData] = useState('');
  const [id, setId] = useState('');
  const [name, setName] = useState('');

  const onCreate = ({ id, name }) => {
    console.log(name);
    setNewData({ id: id, name: name });
  }

  const columns = [
    { value: 'id', title: 'Id', style: { textAlign: 'center' } },
    { value: 'shiftName', title: 'Shift Name', style: { textAlign: 'center' } },
    { value: 'startTime', title: 'Start Time', style: { textAlign: 'center' } },
    { value: 'endTime', title: 'End Time', style: { textAlign: 'center' } },
    { value: 'allowTime', title: 'Allow Time', style: { textAlign: 'center' } },
    { value: 'status', title: 'Staus', style: { textAlign: 'center' } },
    {
      value: 'action', title: 'Action', style: { textAlign: 'center' }, actionsComponent: ((row) => {
        return (<>
          <button className="btn btn-light-primary btn-sm" onClick={() => onAction(row)}>
            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-1 svg-icon-3' />
            Edit
          </button>
        </>)

      })
    }
  ]
  const filters = {
    id: {
      filterType: 'like',
      filterValue: '',
    },
    shiftName: {
      filterType: 'like',
      filterValue: '',
    },
    startTime: {
      filterType: 'like',
      filterValue: '',
    },
    endTime: {
      filterType: 'like',
      filterValue: '',
    },
    allowTime: {
      filterType: 'like',
      filterValue: '',
    },
    status: {
      filterType: 'like',
      filterValue: '',
    }

  }

  const url = process.env.REACT_APP_API_URL + 'shift_dt'

  const onAction = (data) => {
    //console.log(data)
    setId(data.id);
    setName(data.shiftName);

  }

  return (
    <>
      <div className='card card-custom'>
        <div className='card-header'>
          <div className='card-title'>
            <KTSVG
              path='/media/icons/duotune/text/txt001.svg'
              className='svg-icon-1 svg-icon-primary'
            />
            <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
              Shift
            </span>
          </div>
        </div>

        <div className='card-body'>
          <ShiftCreate onCreate={onCreate} id={id} name={name} />
          <DataTable url={url} columns={columns} filters={filters} newData={newData} />
        </div>
      </div>
    </>
  )
}

export default Shift;
