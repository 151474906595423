import React, {useState,useEffect} from 'react'
import Swal from 'sweetalert2'
import {Modal, Button} from 'react-bootstrap'
import {postRequestOptions} from '../helpers/Fetchwrapper'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import Spinner from 'react-bootstrap/Spinner'
import AsyncSelect from 'react-select/async'
import {getRequestOptions} from '../components/GetToken'

const customStylesSelect = {
  control: (provided) => ({
    ...provided,
    height: 'calc(1.35em + 1.1rem + 2px)',
    minHeight: '35px',
  }),
  valueContainer: (provided) => ({
    ...provided,
  }),
}

export default function ModalEmployeeWorksheet({show, handleClose, idWorksheet, idOrderWip}) {
  const [isSubmitDisable, setSubmitDisable] = useState(false)
  const [loading, setLoading] = useState(false)
  const [employee, setEmployee] = useState(null)
  const [startDateTime, setStartDateTime] = useState("")
  const [endDateTime, setEndDateTime] = useState("")
  const [duration, setDuration] = useState("")

  console.log('idWorksheet', idWorksheet)

    useEffect(() => {
        setEmployee(null)
        setStartDateTime("")
        setEndDateTime("")
        setDuration("")
    }, [show])

    useEffect(() => {
        if (idWorksheet !== null) { // Change the condition to fetch data when idWorksheet is not null
            const fetchData = async () => {
                try {
                    const resp = await fetch(process.env.REACT_APP_API_URL + 'employee_worksheet_details/' + idWorksheet, getRequestOptions())
                    const respData = await resp.json();
                    
                    if (respData.success === true) {
                        const data = respData.data;
                        setEmployee({ value: data.idEmployee, label: `${data.employeeName} - ${data.employeeCode}` })
                        setStartDateTime(data.startDateTime)
                        setEndDateTime(data.endDateTime)
                        setDuration(data.duration)
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                    // Handle error, e.g., show an error message to the user
                }
            };
    
            fetchData();
        }
    }, [idWorksheet]);


  const loadOptions = (inputValue, callback) => {
    fetch(
      `${process.env.REACT_APP_API_URL}production_employee_search?q=` + inputValue,
      getRequestOptions()
    )
      .then((resp) => {
        return resp.json()
      })
      .then((resp) => {
        const options = resp.data.map((item) => {
          return {...item, value: item.id, label: `${item.employeeName} - ${item.employeeCode}`}
        })
        callback(options)
      })
  }

  const handleEmployeeChange = (item) => {
    setEmployee(item)
  }

  const handleEmployeeSheetStartDateTimeChange = (e) => {
    setStartDateTime(e.target.value)
    const newduration = calculateDuration(e.target.value, endDateTime)
    setDuration(newduration)
  }

  const handleEmployeeSheetEndDateTimeChange = (e) => {
    setEndDateTime(e.target.value)
    const newduration = calculateDuration(startDateTime, e.target.value)
    setDuration(newduration)
    //console.log('duration', newduration);
  }

  const calculateDuration = (startDateTime, endDateTime) => {
    if (startDateTime === '' || endDateTime === '') {
      return ''
    }
    const start = new Date(startDateTime)
    const end = new Date(endDateTime)
    const diff = Math.abs(end - start)
    const minutes = Math.floor(diff / 1000 / 60)
    return minutes
  }

  const completeTaskSubmit = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Yes, Save it!',
    }).then((result) => {
      if (result.value) {
        setSubmitDisable(true)
        setLoading(true)
        const formData = {
          idWorksheet: idWorksheet,
          idOrderWip: idOrderWip,
          idEmployee: employee?.value,
          startDateTime: startDateTime,
          endDateTime: endDateTime,
          duration: duration
        }
        
        const requestOptions = postRequestOptions(formData)

        const apiurl = idWorksheet ? process.env.REACT_APP_API_URL + 'employee_worksheet_update' : process.env.REACT_APP_API_URL + 'employee_worksheet_save'

        fetch(apiurl, requestOptions)
          .then((resp) => {
            return resp.json()
          })
          .then((resp) => {
            setLoading(false)
            setSubmitDisable(false)
            if (resp.success === true) {
              const successMsg = resp.successMessage
              Swal.fire({
                icon: 'success',
                title: 'Success',
                text: successMsg,
              })

              handleClose(true)
            } else {
              const errorsMessage = validationErrorMsg(resp.errors).join('<br>')
              Swal.fire({
                icon: 'error',
                title: 'Error',
                html: errorsMessage,
              })
            }
          })
          .catch((error) => {
            setLoading(false)
            setSubmitDisable(false)
            console.log(error, 'catch the hoop')
          })
      }
    })
  }

  return (
    <Modal show={show} onHide={() => handleClose(true)} size='lg'>
      <Modal.Header closeButton>
        <Modal.Title>Employee Worksheet</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='form-group row'>
          <label htmlFor='poNo' className='col-md-4 col-form-label' style={{textAlign: 'right'}}>
            Employee Name<span className='required text-danger'></span>
          </label>
          <div className='col-md-6'>
            <AsyncSelect
              value={employee}
              defaultOptions
              loadOptions={loadOptions}
              placeholder='Select Employee'
              onChange={handleEmployeeChange}
              styles={customStylesSelect}
              menuPosition='fixed'
            />
          </div>
        </div>

        <div className='form-group row'>
          <label
            htmlFor='startDateTime'
            className='col-4 col-form-label'
            style={{textAlign: 'right'}}
          >
            Start Date Time
          </label>
          <div className='col-6'>
            <input
              type='datetime-local'
              name='startDateTime'
              className='form-control form-control-sm'
              placeholder='End Date Time'
              value={startDateTime}
              onChange={handleEmployeeSheetStartDateTimeChange}
            />
          </div>
        </div>

        <div className='form-group row'>
          <label
            htmlFor='endDateTime'
            className='col-4 col-form-label'
            style={{textAlign: 'right'}}
          >
            End Date Time
          </label>
          <div className='col-6'>
            <input
              type='datetime-local'
              name='endDateTime'
              className='form-control form-control-sm'
              placeholder='End Date Time'
              value={endDateTime}
              onChange={handleEmployeeSheetEndDateTimeChange}
            />
          </div>
        </div>

        <div className='form-group row'>
          <label
            htmlFor='completeNote'
            className='col-4 col-form-label'
            style={{textAlign: 'right'}}
          >
            Duration
          </label>
          <div className='col-6'>
            <input
              type='text'
              name='duration'
              className='form-control form-control-sm'
              value={duration}
              readOnly
              placeholder='Duration'
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant='primary' onClick={completeTaskSubmit} size="sm">
            Complete
        </Button> */}
        <button
          disabled={isSubmitDisable || loading}
          className='btn btn-sm btn-primary me-2'
          onClick={completeTaskSubmit}
        >
          {loading && (
            <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
          )}
          { idWorksheet   ? 'Update' : 'Save' }
        </button>

        <Button variant='secondary' onClick={() => handleClose(true)} size='sm'>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
