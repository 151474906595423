import React, {useState,useEffect,createContext} from "react";


export const userPermissionContext = createContext();

const UserPagePermission = (props) => {

    const [loading, setLoading] = useState(false);
	const [pageList, setPageList] = useState([]);
	const [pagesLink, setPagesLink] = useState([]);

	useEffect(() => {
		getUserPages();
		getUserPagesLink();
	}, []);

	const getCookie = (name) => {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2) return parts.pop().split(';').shift();
	}

	const getUserPages = () => {
		setLoading(true);
		//const jwt = { token: getCookie("MyToken") }
		let token = localStorage.getItem('MyToken');
		const  jwt = JSON.parse(token);
		console.log(jwt);


		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },

		};
		fetch(process.env.REACT_APP_API_URL + "getUserPages", requestOptions)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				setLoading(false);
				const menu = resp.data.map(function (row) {
					return { ...row, totalSubMenu: row.subMenu.length }
				});
				setPageList(menu);
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});

	}

	const getUserPagesLink = () => {
		//const jwt = { token: getCookie("MyToken") };
		let token = localStorage.getItem('MyToken');
		const  jwt = JSON.parse(token);

		const requestOptions = {
			method: 'GET',
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + jwt.token },

		};
		fetch(process.env.REACT_APP_API_URL + "getUserPagesLink", requestOptions)
			.then((resp) => {
				return resp.json()
			})
			.then((resp) => {
				setPagesLink(resp.data);
			})
			.catch((error) => {
				console.log(error, "catch the hoop")
			});

	}

	//const jwt = { token: getCookie("MyToken") };
	let token = localStorage.getItem('MyToken');
	const  jwt = JSON.parse(token);
	const value = {pageList,loading,pagesLink,jwt};
    return (
        <userPermissionContext.Provider value={value}>
            {props.children}
        </userPermissionContext.Provider>
    )
}
export default UserPagePermission