/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"
import { KTSVG } from '../../_metronic/helpers'
import { getRequestOptions } from '../components/GetToken'
import { deleteRequestOptions } from '../helpers/Fetchwrapper'
import { Tab, Tabs } from 'react-bootstrap-v5'
import ModalEmployeeWorksheet from '../components/ModalEmployeeWorksheet'
import Swal from 'sweetalert2'


function OrderWipDetails() {
    const { id } = useParams();
    const [details, setDetails] = useState('');

    const [showEmployeeWorksheetModal, setShowEmployeeWorksheetModal] = useState(false);

    const handleClose = () => setShowEmployeeWorksheetModal(false);

    const handleShow = () => setShowEmployeeWorksheetModal(true);

    const handleEmployeeWorksheetModalClose = (hasSave) => {
        if (hasSave) {
        setShowEmployeeWorksheetModal(false);
        getOrderWipDetails();
        }
        

    }

    const [idWorksheet, setIdWorksheet] = useState(null);

    const handleAddNew = () => {
        setIdWorksheet(null);
        handleShow();
    }



    useEffect(() => {
        getOrderWipDetails();
    }, []);

    const getOrderWipDetails = () => {
        fetch(`${process.env.REACT_APP_API_URL}order/wip_details/` + id, getRequestOptions())
            .then((resp) => {
                return resp.json()
            }
            )
            .then((resp) => {
                setDetails(resp.data);
            })

    }

    const deleteWorksheet = (id) => {


        return (e) => {
            Swal.fire({
                title: 'Are you sure?',
                text: 'You want to delete this worksheet?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, keep it'
            }).then((result) => {
                if (result.isConfirmed) {
                    fetch(`${process.env.REACT_APP_API_URL}employee_worksheet_delete/` + id, deleteRequestOptions() )
                        .then((resp) => {
                            return resp.json()
                        })
                        .then((resp) => {
                            if (resp.success === true) {
                                getOrderWipDetails();
                                Swal.fire('Deleted!', 'Employee Worksheet  has been deleted.', 'success')
                            } else {
                                Swal.fire('Error!', 'Something went wrong', 'error')
                            }
                        })
                }
            })
        }
    }

    const editWorksheet = (id) => {
        setIdWorksheet(id);
        handleShow();
    }

    return (
        <>
        <div className='card card-custom'>
            <div className='card-header'>
                <div className='card-title'>
                    <KTSVG
                        path='/media/icons/duotune/text/txt001.svg'
                        className='svg-icon-1 svg-icon-primary'
                    />
                    <span className='text fw-bolder' style={{ marginLeft: '10px' }}>
                        Work-in-progress Details
                    </span>
                </div>
            </div>
            <div className='card-body'>
                <div className='ml-12'>
                    <div className="row">
                        <div className="col-md-6">
                            <table className='table table-bordered table-hover'>
                                <tbody>
                                    <tr>
                                        <th>Order No</th>
                                        <td>{details?.poNo}</td>
                                    </tr>
                                    <tr>
                                        <th>Operation</th>
                                        <td>{details?.operationName}</td>
                                    </tr>
                                    <tr>
                                        <th>Production Item</th>
                                        <td>{details?.itemName}</td>
                                    </tr>
                                    <tr>
                                        <th>Production Qty</th>
                                        <td>{details?.productionQty}</td>
                                    </tr>
                                    <tr>
                                        <th>Work-in-progress Date</th>
                                        <td>{details?.orderWipDate}</td>
                                    </tr>
                                    <tr>
                                        <th>Bom Duration (Minutes)</th>
                                        <td>{details?.bomDuration}</td>
                                    </tr>
                                    <tr>
                                        <th>Actual Duration (Minutes)</th>
                                        <td>{details?.actualDuration}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-6">
                            <div className='card card-custom'>
                                <div className='card-header'>
                                    <div className='card-title'>
                                        <h3 className='card-label'>	Wip History</h3>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='timeline timeline-3' style={{ height: '250px', overflow: 'auto' }}>
                                        <div className='timeline-items'>
                                            {details?.status_history?.map((item, index) => (
                                                <div className='timeline-item' key={index}>
                                                    <div className='timeline-media'>
                                                        {item.status === 'created' && <i className='fa fa-plus text-primary'></i>}
                                                    </div>
                                                    <div className='timeline-content'>
                                                        <div className='d-flex align-items-center justify-content-between mb-3'>
                                                            <div className='mr-4'>
                                                                <span
                                                                    className='mr-3 font-weight-bolder'
                                                                    style={{ fontSize: '15px', color: '#e35b5a' }}
                                                                >
                                                                    Work-in-progress has been
                                                                    {item.status === 'created' && ' Created'}
                                                                    <span className='ml-2' style={{ color: 'black' }}> at {item.create_date}</span>
                                                                </span>
                                                                {item.status === 'created' && (
                                                                    <>
                                                                        <p className='mr-3'>
                                                                            Created By :{' '}
                                                                            <a href='/#'>
                                                                                <b>{item.fullName}</b>
                                                                            </a>
                                                                        </p>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='form-group row ml-5'>
							<Tabs defaultActiveKey='wipDetails' id='uncontrolled-tab-example' className='mb-3' >
								<Tab eventKey='wipDetails' title='WIP Details'>
								

                    <div className='col-md-12'>
                        <div className='table-responsive'>
                            <table
                                className='table table-bordered table-hover'
                            >
                                <thead>
                                    <tr>
                                        <th>SL</th>
                                        <th style={{ width: '25%' }}>Component/ Raw Material</th>
                                        <th>Code</th>
                                        <th>Unit</th>
                                        <th>BOM Quantity</th>
                                        <th>Actual Quantity </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {details.production_order_wip_components && details.production_order_wip_components.length > 0 && details.production_order_wip_components.map((item, index) => {

                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.itemName}</td>
                                                <td>{item.itemCode}</td>
                                                <td>{item.unitName}</td>
                                                <td>{item.bomQty}</td>
                                                <td>{item.actualQty}</td>
                                            </tr>
                                        )

                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    </Tab>
						<Tab eventKey='employeeWorkshhet' title='Employee Work Shhet'>

							<div className='col-12'>
                            <button className='btn btn-primary btn-sm ml-2 mb-2' onClick={handleAddNew}>Add new </button>
                            {details.production_order_wip_employee_sheet && details.production_order_wip_employee_sheet.length > 0 &&
                        <div className='col-md-12 mt-5'>
                           
                            <div className='table-responsive'>
                                <table
                                    className='table table-bordered table-hover'
                                >
                                    <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th>Employee Name</th>
                                            <th>Employee Code </th>
                                            <th>Start Time</th>
                                            <th>End Time</th>
                                            <th>Duration (Minutes)</th>
                                             <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {details.production_order_wip_employee_sheet.map((item, index) => {

                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.employeeName}</td>
                                                    <td>{item.employeeCode}</td>
                                                    <td>{item.startDateTime}</td>
                                                    <td>{item.endDateTime}</td>
                                                    <td>{item.duration}</td>
                                                    <td>
                                                        <button className='btn btn-primary btn-sm' style={{marginRight:'5px'}} onClick={ () => editWorksheet(item.id_employee_work_sheet) }>Edit</button>
                                                        <button className='btn btn-danger btn-sm' onClick={deleteWorksheet(item.id_employee_work_sheet)} >Delete</button>
                                                    </td>
                                                </tr>
                                            )

                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
							</div>	
						</Tab>

						</Tabs>
						</div>
                    {details.production_order_wip_time_details && details.production_order_wip_time_details.length > 0 &&
                        <div className='col-md-12 mt-5'>
                            <div className='table-responsive'>
                                <table
                                    className='table table-bordered table-hover'
                                >
                                    <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th>Start Time</th>
                                            <th>End Time</th>
                                            <th>Duration (Minutes)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {details.production_order_wip_time_details.map((item, index) => {

                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.start_date_time}</td>
                                                    <td>{item.end_date_time}</td>
                                                    <td>{item.duration}</td>
                                                </tr>
                                            )

                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>

        <ModalEmployeeWorksheet
            show={showEmployeeWorksheetModal}
            handleClose={handleEmployeeWorksheetModalClose} 
            idWorksheet={idWorksheet} idOrderWip={id}
        />
        </>

    )

}

export default OrderWipDetails