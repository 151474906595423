import React, { useState,useEffect } from 'react'
import Swal from 'sweetalert2'
import { Modal, Button } from 'react-bootstrap'
import { postRequestOptions } from '../helpers/Fetchwrapper'
import validationErrorMsg from '../helpers/ValidationErrorMsg'
import Spinner from 'react-bootstrap/Spinner'

export default function WipQcModal({ show, handleClose, wipQcPropsData }) {


    const [orderId, setOrderId] = useState(null);
    const [operationId, setOperationId] = useState(null);
    const [operationName, setOperationName] = useState(null);
    const [buId , setBuId] = useState(null);
    const [qcDate, setQcDate] = useState(null);
    const [productionQty, setProductionQty] = useState(wipQcPropsData.productionQty);
    const [completedQcQty, setCompletedQcQty] = useState(null);
    const [qcPassedQty, setQcPassedQty] = useState(wipQcPropsData.qcPassedQty);
    const [qcDamagedQty, setQcDamagedQty] = useState(wipQcPropsData.qcDamagedQty);
    const [qcQty , setQcQty] = useState(null);
    const [qcDamageQty, setQcDamageQty] = useState(null);
    const [productionItem, setProductionItem] = useState(null);
    const [qcTolerancePercentage, setQcTolerancePercentage] = useState(0);
    const [remarks, setRemarks] = useState(null);
  


    const [isSubmitDisable, setSubmitDisable] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setOrderId(wipQcPropsData.orderId);
        setOperationId(wipQcPropsData.operationId);
        setOperationName(wipQcPropsData.operationName);
        setBuId(wipQcPropsData.buId);
        setProductionQty(wipQcPropsData.productionQty);
        setCompletedQcQty(wipQcPropsData.doneQcQty);
        setQcPassedQty(wipQcPropsData.qcPassedQty);
        setQcDamagedQty(wipQcPropsData.qcDamagedQty);
        setProductionItem(wipQcPropsData.itemName);
        setQcTolerancePercentage(wipQcPropsData.qcTolerancePercentage);
    }, [wipQcPropsData])

   

    const cancel = () => {
        setQcQty(null);
        setQcDamageQty(null);
        handleClose(false)
    }



  


    const completeTaskSubmit = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes, Save it!'
        }).then((result) => {
            if (result.value) {
                setSubmitDisable(true);
                setLoading(true);
                const formData = {
                    buId : buId,
                    orderId: orderId,
                    qcDate: qcDate,
                    operationId: operationId,
                    qcQty: qcQty,
                    qcDamageQty: qcDamageQty,
                    remarks: remarks
                }
                const requestOptions = postRequestOptions(formData)

                fetch(process.env.REACT_APP_API_URL + 'wip_qc_save', requestOptions)
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((resp) => {
                        setLoading(false);
                        setSubmitDisable(false);
                        if (resp.success === true) {
                            const successMsg = resp.successMessage
                            Swal.fire({
                                icon: 'success',
                                title: 'Success',
                                text: successMsg,
                            })

                            handleClose(true)
                        } else {
                            const errorsMessage = validationErrorMsg(resp.errors).join('<br>')
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                html: errorsMessage,
                            })
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        setSubmitDisable(false);
                        console.log(error, 'catch the hoop')
                    })
            }
        })

    }

    return (
        <Modal show={show} onHide={() => cancel()} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>WIP QC</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <div className='card-body'>

                    <div className='ml-12'>
                     
                        <div className='form-group row'>
                            <label htmlFor='operationName' className='col-4 col-form-label'>
                                Operation Name<span className="required text-danger"></span>
                            </label>
                            <div className='col-6'>
                                <input type="text"
                                    name='operationName'
                                    id='operationName'
                                    value={operationName || ''}
                                    readOnly
                                    className='form-control form-control-sm'
                                />
                            </div>
                        </div>
                        
                        <div className='form-group row'>
                            <label htmlFor='productionItem' className='col-4 col-form-label'>
                                Production Item<span className="required text-danger"></span>
                            </label>
                            <div className='col-6'>
                                <input type="text"
                                    name='productionItem'
                                    id='productionItem'
                                    value={productionItem || ''}
                                    readOnly
                                    className='form-control form-control-sm'
                                />
                            </div>
                        </div>

                        <div className='form-group row'>
                            <label htmlFor='completeDate' className='col-4 col-form-label'>
                                QC Date<span className="required text-danger"></span>
                            </label>
                            <div className='col-6'>
                                <input type="date"
                                    name='qcDate'
                                    id='qcDate'
                                    value={qcDate || ''}
                                    onChange={(e) => setQcDate(e.target.value)}
                                    className='form-control form-control-sm'
                                />
                            </div>
                        
                        </div>

                        <div className='form-group row'>
                            <label htmlFor='productionQty' className='col-4 col-form-label'>
                                Production Qty<span className="required text-danger"></span>
                            </label>
                            <div className='col-6'>
                                <input type="text"
                                    name='productionQty'
                                    id='productionQty'
                                    value={productionQty }
                                    readOnly
                                    className='form-control form-control-sm'
                                />
                            </div>
                        </div>


                        {/* completed Qc  quantity */}

                        <div className='form-group row'>
                            <label htmlFor='don' className='col-4 col-form-label'>
                                Completed QC Qty<span className="required text-danger"></span>
                            </label>
                            <div className='col-6'>
                                <input type="text"
                                    name='completedQcQty'
                                    id='completedQcQty'
                                    value={completedQcQty}
                                    readOnly
                                    className='form-control form-control-sm'
                                />
                            </div>
                        </div>

                        <div className='form-group row'>
                            <label htmlFor='qcQty' className='col-4 col-form-label'>
                                QC Qty<span className="required text-danger"></span>
                            </label>
                            <div className='col-6'>
                                <input type="number"
                                    name='qcQty'
                                    id='qcQty'
                                    value={qcQty}
                                    onChange={(e) => setQcQty(e.target.value)}
                                    className='form-control form-control-sm'
                                />
                                </div>
                            </div>

                            <div className='form-group row'>
                            <label htmlFor='qcDamageQty' className='col-4 col-form-label'>
                                QC Damage Qty
                            </label>
                            <div className='col-6'>
                                <input type="number"
                                    name='qcDamageQty'
                                    id='qcDamageQty'
                                    value={qcDamageQty}
                                    onChange={(e) => setQcDamageQty(e.target.value)}
                                    className='form-control form-control-sm'
                                />
                                </div>
                            </div>

                            {/* Damage percentage */}
                            <div className='form-group row'>
                            <label htmlFor='damagePercentage' className='col-4 col-form-label'>
                                Damage Percentage
                            </label>
                            <div className='col-6'>
                                <input
                                    type="text"
                                    name='damagePercentage'
                                    id='damagePercentage'
                                    value={
                                        (qcDamageQty != null && qcQty != null)
                                            ? ((qcDamageQty / qcQty) * 100).toFixed(2)
                                            : 0
                                    }
                                    readOnly
                                    className='form-control form-control-sm'
                                />
                            </div>
                            </div>

                            {/* QC Tolerance Percentage */}
                            <div className='form-group row'>
                            <label htmlFor='qcTolerancePercentage' className='col-4 col-form-label'>
                                QC Tolerance Percentage
                            </label>
                            <div className='col-6'>
                                <input
                                    type="text"
                                    name='qcTolerancePercentage'
                                    id='qcTolerancePercentage'
                                    value={qcTolerancePercentage}
                                    readOnly
                                    className='form-control form-control-sm'
                                />
                            </div>
                            </div>

                            {/* remarks */}
                            <div className='form-group row'>
                            <label htmlFor='remarks' className='col-4 col-form-label'>
                                Remarks { qcDamageQty >0 &&  <span className="required text-danger"></span> }
                            </label>
                            <div className='col-6'>
                                <textarea type="text"
                                    name='remarks'
                                    id='remarks'
                                    value={remarks}
                                    onChange={(e) => setRemarks(e.target.value)}
                                    className='form-control form-control-sm'
                                />
                                </div>
                             </div>
                        
                        
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {/* <Button variant='primary' onClick={completeTaskSubmit} size="sm">
            Complete
        </Button> */}
                {/* { JSON.stringify(qcQty)} */}
                <button disabled={isSubmitDisable || loading} className="btn btn-sm btn-primary me-2" onClick={completeTaskSubmit}>
                    {loading &&
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    }
                    <span>  Save</span>
                </button>

                <Button variant='secondary' onClick={()=> cancel()} size="sm">
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
